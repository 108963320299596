import { Button, Container, Grid } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Faculty.css';

const KSLM = () => {
    const buttonStyles1 = {
        backgroundColor: 'transparent',
        border: '2px solid #f21111', // Blue outline
        color: '#f21111', // Blue text color
        paddingLeft: '2em',
        paddingRight: '2em'
    };
    const navigate = useNavigate();
    const handleBtnClick = (path) => {
        navigate(path);
    }
    return (
        <div>
            <Container>
                <Grid container="container" spacing={2}>
                    <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
                        <h3 className='fac-title'>KIU School Of Agriculture And Livestock Management</h3>
                    </Grid>
                </Grid>
            </Container>

            <Container>
                <Grid container="container" spacing={2}>
                    <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
                        <p className='fac-descrip'>

                            Our "Diploma in Agriculture and Livestock Management" program at KIU's
                            Agriculture and Livestock Management Training School offers a solid foundation
                            in agricultural concepts and modern livestock management techniques. This
                            professional qualification equips you with essential insights into farming and
                            livestock practices, both locally and globally. Whether you aspire to oversee
                            farm operations, assist farmers in improving their methods, engage in
                            agribusiness management, contribute to agricultural research, or specialize in
                            livestock farming, this diploma opens doors to rewarding careers in agriculture
                            and livestock management, both in Sri Lanka and on an international scale. Join
                            us to cultivate your skills and embark on a dynamic journey in this thriving
                            industry.
                        </p>
                    </Grid>
                </Grid>
            </Container>

            {/* <Container>
                <Grid container="container" spacing={2}>
                    <Grid item="item" xs={12} sm={12} md={6} lg={6} xl={6}>
                        <h3 className='fac-dean-hed'>Dean</h3>
                        <p className='fac-descrip1'>
                            Prof. Koliya Pulasinghe<br/>BSc Eng. (Hons) in Computer Systems & Engineering,
                                University of Moratuwa, Sri Lanka, 1996<br/>
                            Contact: +94 77 123 4567<br/>
                            Email: abc@gmail.com
                        </p>
                    </Grid>

                    <Grid item="item" xs={12} sm={12} md={6} lg={6} xl={6}>
                        <h3 className='fac-hod-hed'>Head of Department</h3>
                        <p className='fac-descrip1'>
                            Prof. Koliya Pulasinghe<br/>BSc Eng. (Hons) in Computer Systems & Engineering,
                                University of Moratuwa, Sri Lanka, 1996<br/>
                            Contact: +94 77 123 4567<br/>
                            Email: abc@gmail.com
                        </p>
                    </Grid>
                </Grid>
            </Container> */}

            {/* <Container>
                <Grid container="container" spacing={2}>
                    <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
                        <h3 className='fac-depts-hed'>Departments</h3>
                        <ul className='fac-depts-list'>
                            <li className='fac-depts-list-item'>Department Of Research And Development</li>
                            <li className='fac-depts-list-item'>Department Of Nursing Education</li>
                            <li className='fac-depts-list-item'>Department Of Clinical Nursing</li>
                            <li className='fac-depts-list-item'>Department Of Basic Sciences</li>
                            <li className='fac-depts-list-item'>Department Of Care Advancement</li>
                        </ul>
                    </Grid>
                </Grid>
            </Container> */}

            {/* <Container>
                <Grid container="container" spacing={2}>
                    <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
                        <h3 className='fac-fas-hed'>Facilities</h3>
                        <p className='fac-descrip1'>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                            incididunt ut labore et dolore magna aliqua. Amet cursus sit amet dictum sit
                            amet. Amet mattis vulputate enim nulla aliquet porttitor lacus luctus. Mi quis
                            hendrerit dolor magna eget est lorem ipsum. Arcu cursus vitae congue mauris
                            rhoncus. Ornare lectus sit amet est placerat in egestas erat imperdiet. Aliquam
                            malesuada bibendum arcu vitae elementum curabitur vitae. Blandit cursus risus at
                            ultrices mi tempus. Etiam sit amet nisl purus in. Mollis aliquam ut porttitor
                            leo a. Est pellentesque elit ullamcorper dignissim cras tincidunt lobortis
                            feugiat vivamus. Posuere sollicitudin aliquam ultrices sagittis orci a
                            scelerisque purus. Turpis massa tincidunt dui ut. Sem nulla pharetra diam sit
                            amet. Risus sed vulputate odio ut enim blandit.
                        </p>
                    </Grid>
                </Grid>
            </Container> */}

            {/* <Container>
                <Grid container="container" spacing={2}>
                    <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Button style={buttonStyles1} onClick={() => handleBtnClick('/WeOffer')}>View Courses</Button>
                    </Grid>
                </Grid>
            </Container> */}
        </div>
    )
}

export default KSLM