import React from "react";
import { Button, Container, Grid, Typography } from "@mui/material";
import "./Ugcrs.css";
import BAPDF from "../../brochure/sample.pdf";
import Accounting from "../../assets/Accounting.png";

const AC = ({ onClose }) => {
  const buttonStyles1 = {
    backgroundColor: "transparent",
    border: "2px solid #44DA18", // Blue outline
    color: "#44DA18", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const buttonStyles2 = {
    backgroundColor: "transparent",
    border: "2px solid #00AAFD", // Blue outline
    color: "#00AAFD", // Blue text color
  };
  const buttonStyles3 = {
    backgroundColor: "transparent",
    border: "2px solid #FF0B00", // Blue outline
    color: "#FF0B00", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const handleDownloadBrochure = () => {
    const link = document.createElement("a");
    link.href = BAPDF;
    link.download = "Bachelor of Management Honours in Business Analytics.pdf";
    link.click();
  };
  return (
    <div>
      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography
              variant="h2"
              sx={{
                fontFamily: "Kanit, sans-serif",
                fontSize: "35px",
                marginTop: "5%",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              Bachelor of Management Honours in Accounting
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            sx={{
              // marginLeft: '6%'
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography className="ug-crs-des1">
              Welcome to the Department of Accounting at KIU Sri Lanka!
              <br />
              The Bachelor of Management Honours in Accounting degree at KIU is
              tailored for those seeking a rewarding career in accounting and
              finance. This comprehensive four-year program, awarded by KIU,
              prepares students for successful roles in the accounting
              profession within the global financial sector. Aligned with the
              curricula of other Sri Lankan universities and adhering to
              international standards in management education, this degree
              offers a well-rounded education encompassing accounting, auditing,
              taxation, financial analysis, working capital management,
              investment analysis & portfolio management, and more.
              <br />
              Industrial placements with leading companies provide invaluable
              industry exposure and research opportunities. Delivered in
              English, the program comprises 120 credits of academic coursework,
              ensuring a solid foundation for aspiring accountants and finance
              professionals, both domestically and internationally.
            </Typography>
          </Grid>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              alt="KIU Uni"
              src={Accounting}
              className="about-vc-img"
              style={{
                width: "100%",
                height: "60%",
                padding: "1em",
              }}
            />
          </Grid>
        </Grid>
      </Container>

      <div className="ug-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des2">
              <p>
                Level: SLQF 6
                <br />
                Duration: 4 years
                <br />
                Credit Points: 120
                <br />
                Intakes: March and September
                <br />
              </p>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des3">
              <h3>Career Opportunities</h3>
              <ol>
                <li>Accounting Assistant </li>
                <li>Accountant </li>
                <li>Accounting Manager </li>
                <li>Budget Analyst </li>
                <li>Certified Internal Auditor </li>
                <li>Forensic Accountant </li>
                <li>Government Accountant</li>
                <li>Chief Financial Officer - CFO </li>
              </ol>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des4">
              <h5>Entry Requirements</h5>
              <ul>
                <li>
                  Three (03) passes at G.C.E. Advanced Level examination in any
                  subject stream with a minimum "C" pass for mathematics at
                  G.C.E (O/L) examination.
                </li>
                <li>Equivalent qualification for international students. </li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="ug-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des5">
              <h5>Program Structure</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des6">
              <h5>Year 1</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des7">
              <h5>Semester 1</h5>
              <ul>
                <li>Management Principles and Practices</li>
                <li>Business & Environment</li>
                <li>Business Mathematics</li>
                <li>Micro Economics</li>
              </ul>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des8">
              <h5>Semester 2</h5>

              <ul>
                <li>Elements of Sociology and Psychology</li>
                <li>Principles of Accounting</li>
                <li>Macro Economics</li>
                <li>Business Communication</li>
                <li>Business Statistics</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des6">
              <h5>Year 2</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des7">
              <h5>Semester 1</h5>
              <ul>
                <li>Management Information System</li>
                <li>Management Accounting</li>
                <li>Organizational Behavior</li>
                <li>Marketing Management</li>
                <li>Human Resources Management</li>
              </ul>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des8">
              <h5>Semester 2</h5>

              <ul>
                <li>Organization Theory</li>
                <li>Financial Management</li>
                <li>Commercial and Industrial Law</li>
                <li>Taxation</li>
                <li>Business Ethics and Corporate Responsibilities</li>
                <li>Accounting Information System (Elective)</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des6">
              <h5>Year 3</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des7">
              <h5>Semester 1</h5>
              <ul>
                <li>Auditing</li>
                <li>Computer Based Business Statistics</li>
                <li>Personality Development</li>
                <li>Entrepreneurship and SME</li>
                <li>Organizational Change and Development</li>
                <li>Advance Financial Accounting & Reporting (Elective)</li>
              </ul>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des8">
              <h5>Semester 2</h5>

              <ul>
                <li>Industrial Training</li>
                <li>Strategic Management</li>
                <li>Conflict Management</li>
                <li>Knowledge Management</li>
                <li>Accounting and Finance Modeling (Elective)</li>
                <li>Corporate Governance, Risks, and Ethics (Elective)</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des6">
              <h5>Year 4</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des7">
              <h5>Semester 1</h5>
              <ul>
                <li>Research Methods and Academic Writing</li>
                <li>Culture and Management</li>
                <li>Working Capital Management</li>
                <li>Investment Analysis and Portfolio Management</li>
                <li>Financial Reporting and Regulations</li>
                <li>Business Valuation Theory and Application (Elective)</li>
              </ul>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des8">
              <h5>Semester 2</h5>

              <ul>
                <li>Research Project</li>
                <li>Operation Management</li>
                <li>Computer Based Accounting</li>
                <li>Forensic Accounting (Elective)</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="ug-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des4">
              <h5>By the end of the course, learners will be able to: </h5>
              <ul>
                <li>
                  Demonstrate understanding of principles, concepts, and
                  theories in the field of accounting and associated areas
                </li>
                <li>
                  Apply accounting-specific knowledge and professional skills in
                  diverse business situations in dynamic business environments{" "}
                </li>
                <li>
                  Demonstrate an understanding of technological advancements and
                  their application to professional and business contexts{" "}
                </li>
                <li>
                  Apply research skills to devise solutions to practical issues
                  pertaining to accounting.{" "}
                </li>
                <li>
                  Build communication skills to effectively communicate in a
                  professional context using appropriate technologies.{" "}
                </li>
                <li>
                  Develop skills to work as a team in any business context by
                  analyzing group dynamics, building empowered work teams,
                  creating a shared vision, and resolving conflicts.{" "}
                </li>
                <li>
                  Build adequate leadership and interpersonal skills in
                  workplaces of cultural and linguistic diversity{" "}
                </li>
                <li>
                  Identify emerging trends in the local and global business
                  environments and create innovations to enhance the efficiency
                  and effectiveness of accounting practices and critically
                  evaluate issues pertaining to the accounting discipline{" "}
                </li>
                <li>
                  Demonstrate understanding and commitment to professional
                  ethics and responsibilities of the accounting practice through
                  behavior{" "}
                </li>
                <li>
                  Demonstrate understanding of social and civic
                  responsibilities, human rights and matters pertaining to
                  sustainability.{" "}
                </li>
                <li>
                  Develop key competencies required for self-directed and
                  lifelong learning to become a professional in the discipline
                  of accounting.{" "}
                </li>
                Explore the world of Accounting at KIU Sri Lanka and join us in
                shaping the futureg.
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="ug-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <a
              href="https://erpv2std.kiu.lk/online-registration/8"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button style={buttonStyles1}>Apply</Button>
            </a>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <Button
              disabled
              style={buttonStyles2}
              onClick={handleDownloadBrochure}
            >
              Download Brochure
            </Button>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <Button style={buttonStyles3} onClick={onClose}>
              Close
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default AC;
