import { Button, Container, Grid } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import "./Faculty.css";

const FOGS = () => {
  const buttonStyles1 = {
    backgroundColor: "transparent",
    border: "2px solid #f21111", // Blue outline
    color: "#f21111", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const navigate = useNavigate();
  const handleBtnClick = (path) => {
    navigate(path);
  };
  return (
    <div>
      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 className="fac-title">Faculty Of Graduate Studies</h3>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <p className="fac-descrip">
              The Faculty of Graduate Studies (FGS) of KIU since 2018 marks its
              commitment to delivering a comprehensive array of postgraduate
              degree programs sanctioned by the University Grants Commission
              (UGC). These programmes, endorsed by rigorous academic standards,
              are tailored to accommodate various educational methodologies
              including research-driven, coursework-centric, or hybrid
              modalities. The faculty boasts a cadre of esteemed scholars and
              industry professionals from esteemed universities and private
              enterprises, whose collective expertise enriches the academic
              milieu. Oversight of academic and administrative affairs within
              the faculty falls under the purview of the Dean who leads a
              cohesive team dedicated to advancing its mission. Furthermore, the
              faculty comprises distinct Boards of Studies, such as the Board of
              Study on Nursing and the Board of Study on Management, each
              responsible for steering pertinent postgraduate curricula.
            </p>

            <p className="fac-descrip">
              <strong>Vision</strong>
              <br />
              Enable the postgraduate to achieve academic and professional
              success both locally and internationally and foster a culture of
              lifelong learning.
            </p>

            <p className="fac-descrip">
              <strong>Mission</strong>
              <br />
              Our mission is to ensure the diversity of postgraduate programmes
              to enhance intellectual and professional achievements across
              disciplines.
            </p>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 className="fac-dean-hed">Dean</h3>
            <p className="fac-descrip1">
              Akila Randika Jayamaha
              <br />
              PhD (Reading)-University of Leicester, United Kingdom, MSc in
              Nursing (KIU), MSc in Applied Epidemiology (University of
              Peradeniya)/BSc Nursing (University of Ruhuna)
              <br />
              Contact: 0777614918 / 0759359834
              <br />
              Email: akila@kiu.ac.lk
            </p>
          </Grid>

          {/* <Grid item="item" xs={12} sm={12} md={6} lg={6} xl={6}>
                        <h3 className='fac-hod-hed'>Head of Department</h3>
                        <p className='fac-descrip1'>
                            Prof. Koliya Pulasinghe<br/>BSc Eng. (Hons) in Computer Systems & Engineering,
                                University of Moratuwa, Sri Lanka, 1996<br/>
                            Contact: +94 77 123 4567<br/>
                            Email: abc@gmail.com
                        </p>
                    </Grid> */}
        </Grid>
      </Container>

      {/* <Container>
                <Grid container="container" spacing={2}>
                    <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
                        <h3 className='fac-depts-hed'>Departments</h3>
                        <ul className='fac-depts-list'>
                            <li className='fac-depts-list-item'>Department of Computer Science</li>
                        </ul>
                    </Grid>
                </Grid>
            </Container> */}

      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 className="fac-fas-hed">Facilities</h3>
            <p className="fac-descrip1">
              The Faculty of Graduate Studies of KIU maintains academic
              excellence in accordance with international benchmarks and offer
              unparalleled access to cutting-edge research facilities, expert
              mentorship, and a vibrant academic community, fostering
              transformative learning experiences and empowering students to
              push the boundaries of knowledge in their chosen fields.
            </p>

            <p className="fac-descrip1">
              <strong>Libraries and Archives</strong>
              <br />
              Access to extensive libraries and archives is pivotal for post
              graduate studies. The faculty provides access to vast collections
              of scholarly materials both in physical and digital formats,
              ensuring students have the resources they need for their studies
              and research endeavors. KIU facilitates access to an extensive
              repository of scholarly research articles and books through both
              physical and digital platforms.
            </p>

            <p className="fac-descrip1">
              <strong>
                Study Spaces, Seminar Rooms, and Presentation Facilities
              </strong>
              <br />A good academic environment is crucial for postgraduate
              students to maintain focus on their academic endeavors. The
              faculty provides a range of settings, including individual study
              rooms, group study rooms, and collaborative spaces, all equipped
              with amenities for scholarly pursuits. Additionally, for seminars,
              workshops, and academic presentations, the faculty offers
              well-appointed seminar rooms and presentation facilities designed
              to accommodate both small group discussions and larger-scale
              academic events.
            </p>

            <p className="fac-descrip1">
              <strong>Computer Labs and IT Services</strong>
              <br />
              In today's digital age, access to technology is paramount. The
              faculty maintains computer labs equipped with the latest hardware
              and software, along with robust IT support services to assist
              students and faculty with their technological needs. Nevertheless,
              students are facilitated with the opportunity to engage in
              learning experiences through both physical and online platforms
              with the latest IT facilities.In today's digital age, access to
              technology is paramount. The faculty maintains computer labs
              equipped with the latest hardware and software, along with robust
              IT support services to assist students and faculty with their
              technological needs. Nevertheless, students are facilitated with
              the opportunity to engage in learning experiences through both
              physical and online platforms with the latest IT facilities.
            </p>

            <p className="fac-descrip1">
              <strong>Career Development Services</strong>
              <br />
              The faculty understands the importance of preparing postgraduate
              students for successful careers beyond academia. Therefore, it
              offers career development services, including workshops, seminars,
              and one-on-one counseling, to help students explore career
              options, develop essential skills, and navigate the job market.
            </p>
          </Grid>
        </Grid>
      </Container>

      {/* <Container>
                <Grid container="container" spacing={2}>
                    <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Button style={buttonStyles1} onClick={() => handleBtnClick('/WeOffer')}>View Courses</Button>
                    </Grid>
                </Grid>
            </Container> */}
    </div>
  );
};

export default FOGS;
