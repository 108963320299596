import {
  Box,
  Button,
  CardContent,
  Container,
  Grid,
  styled,
  Typography,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { Card } from "antd";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useNavigate } from "react-router-dom";
import Icon from "../@core/components/icon";
import quote from "../assets/blockR.png";
import Head from "../components/Head";
import Header from "../components/Header";
import Footer from "../components/footer";
import "./Research.css";

const Research = () => {
  const navigate = useNavigate();
  const cardStyle = {
    // borderRadius: "31px",
    background: "#fdf7fb",
    boxShadow: "22px 22px 20px #d7d2d5, -22px -22px 20px #ffffff",
    transition: "transform 0.3s",
    cursor: "pointer",
    height: "17rem",
  };

  const CustomButton = styled(Button)({
    backgroundColor: "white",
    color: "black",
    borderRadius: "30px",
    padding: "10px 20px",
    textTransform: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontWeight: "bold",
    fontSize: "1.2rem",
    transition: "background-color 0.3s, color 0.3s",
    "& .icon-container .icon": {
      color: "white",
    },
    "&:hover": {
      backgroundColor: "red",
      color: "white",
      "& .icon-container": {
        backgroundColor: "white",
        "& .icon": {
          color: "black",
        },
      },
    },
  });

  const cardStyleMRC = {
    // borderRadius: "31px",
    background: "#323131",
    boxShadow: "22px 22px 20px #d7d2d5, -22px -22px 20px #ffffff",
    transition: "transform 0.3s",
    height: "7rem",
    display: "flex",
    alignItems: "center",
  };

  const deanStyle = {
    fontFamily: "Kanit, sans-serif",
    fontSize: "20px",
    fontWeight: "bold",
    color: "#FC4E4E",
  };

  const newsStyle = {
    // borderRadius: "50px",
    background: "linear-gradient(225deg, #f0f0f0, #cacaca)",
    boxShadow: "20px 20px 60px #bebebe, -20px -20px 60px #ffffff",
    marginTop: "4%",
    marginLeft: "6%",
  };

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}.${month}.${day}`;
  };

  const [news, setNews] = useState([]);

  useEffect(() => {
    // Fetch data from the API
    fetch("https://kiu.ac.lk/api/website/findLast4Research")
      .then((response) => response.json())
      .then((data) => {
        // Assuming the API response is an array of objects with "testamonial" and
        // "thumbnail" properties
        setNews(data || []);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  useEffect(() => {
    // Get the hash from the URL
    const hash = window.location.hash;

    // Check if the hash exists and scroll to the element with the corresponding ID
    if (hash) {
      const targetElement = document.getElementById(hash.substring(1));
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);

  return (
    <div className="research">
      <Header />

      <div className="head-div">
        <Head />
      </div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              marginLeft: "6%",
            }}
          >
            <Typography
              variant="h3"
              sx={{
                fontFamily: "Kanit, sans-serif",
                fontSize: "35px",
                marginTop: "5%",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              Research At KIU
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid
          container="container"
          spacing={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              marginTop: "3%",
              marginLeft: "6%",
            }}
          >
            <Typography
              className="fac-des"
              sx={{
                fontFamily: "Montserrat, sans-serif",
              }}
            >
              "KIU places research at the forefront of innovation, with a
              dedicated team of researchers committed to tackling pressing
              challenges and breaking barriers to unleash new possibilities. Our
              research excellence embodies the potential of young researchers at
              our institution, fostering collaborative efforts across multiple
              disciplines to better serve our communities and address society’s
              challenges.
              <br />
              Join us on a journey of discovery as we explore our research
              endeavors, passionate researchers, and the transformative impact
              of our work!"
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="fac-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              marginLeft: "6%",
            }}
          >
            <Typography
              variant="h3"
              sx={{
                fontFamily: "Kanit, sans-serif",
                fontSize: "35px",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              Our Research Themes
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid
          container="container"
          spacing={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingLeft: "7%",
            paddingRight: "1.5%",
          }}
        >
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={3.5}
            xl={3.5}
            sx={{
              marginTop: "5%",
            }}
          >
            <Card
              style={cardStyle}
              onMouseEnter={(e) =>
                (e.currentTarget.style.transform = "scale(1.05)")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.transform = "scale(1)")
              }
            >
              <Typography
                variant="h5"
                sx={{
                  fontFamily: "Kanit, sans-serif",
                  fontSize: "25px",
                  marginTop: "5%",
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "red",
                }}
              >
                HUMAN HEALTH
              </Typography>
              <Typography
                className="fac-des"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontFamily: "Montserrat, sans-serif",
                }}
              >
                Empowering tomorrow's leaders to enhance human health and
                well-being.
              </Typography>
            </Card>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={3.5}
            xl={3.5}
            sx={{
              marginTop: "5%",
            }}
          >
            <Card
              style={cardStyle}
              onMouseEnter={(e) =>
                (e.currentTarget.style.transform = "scale(1.05)")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.transform = "scale(1)")
              }
            >
              <Typography
                variant="h5"
                sx={{
                  fontFamily: "Kanit, sans-serif",
                  fontSize: "25px",
                  marginTop: "5%",
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "red",
                }}
              >
                BUSINESS AND ECONOMICS
              </Typography>
              <Typography
                className="fac-des"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontFamily: "Montserrat, sans-serif",
                }}
              >
                Nurturing visionaries and innovators in the business and
                economics
              </Typography>
            </Card>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={3.5}
            xl={3.5}
            sx={{
              marginTop: "5%",
            }}
          >
            <Card
              style={cardStyle}
              onMouseEnter={(e) =>
                (e.currentTarget.style.transform = "scale(1.05)")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.transform = "scale(1)")
              }
            >
              <Typography
                variant="h5"
                sx={{
                  fontFamily: "Kanit, sans-serif",
                  fontSize: "25px",
                  marginTop: "5%",
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "red",
                }}
              >
                BIOLOGICAL SCIENCES
              </Typography>
              <Typography
                className="fac-des"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontFamily: "Montserrat, sans-serif",
                }}
              >
                Uncovering the mysteries of the biological world.
              </Typography>
            </Card>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid
          container="container"
          spacing={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingLeft: "6%",
            paddingRight: "1.5%",
          }}
        >
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={3.5}
            xl={3.5}
            sx={{
              marginTop: "5%",
            }}
          >
            <Card
              style={cardStyle}
              onMouseEnter={(e) =>
                (e.currentTarget.style.transform = "scale(1.05)")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.transform = "scale(1)")
              }
            >
              <Typography
                variant="h5"
                sx={{
                  fontFamily: "Kanit, sans-serif",
                  fontSize: "25px",
                  marginTop: "5%",
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "red",
                }}
              >
                LANGUAGE AND LITERATURE
              </Typography>
              <Typography
                className="fac-des"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontFamily: "Montserrat, sans-serif",
                }}
              >
                Unravelling the artistry of words and culture in a dynamic
                academic environment.
              </Typography>
            </Card>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={3.5}
            xl={3.5}
            sx={{
              marginTop: "5%",
            }}
          >
            <Card
              style={cardStyle}
              onMouseEnter={(e) =>
                (e.currentTarget.style.transform = "scale(1.05)")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.transform = "scale(1)")
              }
            >
              <Typography
                variant="h5"
                sx={{
                  fontFamily: "Kanit, sans-serif",
                  fontSize: "25px",
                  marginTop: "5%",
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "red",
                }}
              >
                PSYCHOLOGY
              </Typography>
              <Typography
                className="fac-des"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontFamily: "Montserrat, sans-serif",
                }}
              >
                Investigating the science of the human mind.
              </Typography>
            </Card>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={3.5}
            xl={3.5}
            sx={{
              marginTop: "5%",
            }}
          >
            <Card
              style={cardStyle}
              onMouseEnter={(e) =>
                (e.currentTarget.style.transform = "scale(1.05)")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.transform = "scale(1)")
              }
            >
              <Typography
                variant="h5"
                sx={{
                  fontFamily: "Kanit, sans-serif",
                  fontSize: "25px",
                  marginTop: "5%",
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "red",
                }}
              >
                COMPUTER SCIENCE AND INFORMATICS
              </Typography>
              <Typography
                className="fac-des"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontFamily: "Montserrat, sans-serif",
                }}
              >
                Fueling the digital revolution to shape the future of technology
                and innovation.
              </Typography>
            </Card>
          </Grid>
        </Grid>
      </Container>

      <div className="fac-horizontal-divider"></div>
      <Container>
        <Grid
          container="container"
          spacing={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingLeft: "7%",
            paddingRight: "1.5%",
          }}
        >
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <Card
              style={cardStyleMRC}
              onMouseEnter={(e) =>
                (e.currentTarget.style.transform = "scale(1.05)")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.transform = "scale(1)")
              }
            >
              <Grid
                container="container"
                spacing={2}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Grid item="item" xs={12} sm={12} md={8} lg={8} xl={8}>
                  <Typography
                    variant="h5"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      fontFamily: "Kanit, sans-serif",
                      fontSize: "25px",
                      textAlign: "left",
                      fontWeight: "bold",
                      color: "red",
                    }}
                  >
                    Multidisciplinary Research Conference – 2024
                  </Typography>
                </Grid>
                <Grid item="item" xs={12} sm={12} md={4} lg={4} xl={4}>
                  <CustomButton onClick={() => navigate("/MRC")}>
                    <p className="home-ins-b">Visit Now</p>
                    <Box
                      className="icon-container"
                      width={30}
                      height={30}
                      sx={{
                        mx: 1,
                        background: "black",
                        borderRadius: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        transition: "background-color 0.3s",
                      }}
                    >
                      <Icon
                        icon="iconamoon:arrow-right-2-bold"
                        className="icon"
                        style={{ transition: "color 0.3s" }}
                      />
                    </Box>
                  </CustomButton>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <div className="fac-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              marginLeft: "6%",
            }}
          >
            <Typography
              variant="h3"
              sx={{
                fontFamily: "Kanit, sans-serif",
                fontSize: "35px",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              Why Research At KIU?
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid
          container="container"
          spacing={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              marginTop: "3%",
              marginLeft: "6%",
            }}
          >
            <Typography
              className="fac-des"
              sx={{
                fontFamily: "Montserrat, sans-serif",
              }}
            >
              KIU’s vibrant research community promotes collaboration,
              innovation, and creative solutions to global challenges. We excel
              in various research strengths that set us apart as an esteemed
              institution dedicated to groundbreaking technological
              advancements:
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              marginTop: "1%",
              marginLeft: "3%",
            }}
          >
            <Typography>
              <ul className="res-de-pub-list">
                <li>State-of-the-Art laboratories</li>
                <p
                  style={{
                    paddingLeft: "4%",
                  }}
                >
                  Well-equipped modern laboratories excel in various research
                  areas.
                </p>
                <li>Access to libraries and archives</li>
                <p
                  style={{
                    paddingLeft: "4%",
                  }}
                >
                  Extensive libraries and valuable collections enhance our
                  research capabilities.
                </p>
                <li>Field research resources</li>
                <p
                  style={{
                    paddingLeft: "4%",
                  }}
                >
                  Strong relationships with external organizations provide
                  access to unique research sites.
                </p>
              </ul>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              marginTop: "1%",
              marginLeft: "1%",
            }}
          >
            <Typography className="fac-des">
              <ul className="res-de-pub-list">
                <li>Tech transfer and incubation centers</li>
                <p
                  style={{
                    paddingLeft: "4%",
                  }}
                >
                  A network of industry and business connections supports
                  technology transfer and innovation.
                </p>
                <li>Funding opportunities</li>
                <p
                  style={{
                    paddingLeft: "4%",
                  }}
                >
                  Affiliations with institutions and organizations provide
                  research grants and funding opportunities.
                </p>
                <li>Research workshops</li>
                <p
                  style={{
                    paddingLeft: "4%",
                  }}
                >
                  Interactive workshops foster knowledge and collaboration among
                  our researchers.
                </p>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="fac-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              marginLeft: "6%",
            }}
          >
            <Typography
              variant="h3"
              sx={{
                fontFamily: "Kanit, sans-serif",
                fontSize: "35px",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              Explore Our Panel Of Researches
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid
          container="container"
          spacing={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              marginTop: "3%",
              marginLeft: "6%",
            }}
          >
            <Typography
              className="fac-des"
              sx={{
                fontFamily: "Montserrat, sans-serif",
              }}
            >
              Meet the exceptional minds behind our groundbreaking research and
              discover the stories that define their passion for discovery and
              the profound impact they have on our institution and the world:
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}
            sx={{
              marginTop: "3%",
              marginLeft: "6%",
            }}
          >
            <Box
              sx={{
                width: "200px",
                height: "200px",
              }}
            >
              <LazyLoadImage
                src="https://kiu-website.s3.ap-southeast-1.amazonaws.com/Resource/1721387744867_Akila.jpg"
                alt="researcher"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </Box>
            <Typography className="res-quote-text-s1" sx={{ fontWeight: 600 }}>
              AKILA RANDIKA JAYAMAHA
            </Typography>
            <p className="res-quote-text-s2">
              CHIEF GROWTH OFFICER / DEAN OF THE FACULTY OF GRADUATE STUDIES
            </p>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            sx={{
              marginTop: "3%",
              marginLeft: "6%",
            }}
          >
            <div className="res-quote">
              <img
                alt="KIU Uni"
                src={quote}
                className="blockQ"
              ></img>
            </div>
            <div className="res-quote-text">
              <Typography
                variant="h5"
                sx={{
                  fontFamily: "Kanit, sans-serif",
                }}
              >
                Exploring the complexities within the healthcare sector, paving
                the way for impactful solutions in the pursuit of a prosperous
                nation”.
              </Typography>
              <Typography className="res-foc-txt">
                Research Focus: Healthcare sector complexities for a prosperous
                nation.
              </Typography>
              <Typography
                sx={{
                  fontSize: "13px",
                  textAlign: "end",
                  fontFamily: "Montserrat, sans-serif",
                }}
              >
                Ph.D. (reading) (University of Leicester, UK)
                <br />
                MSc in Epidemiology (University of Peradeniya, SL),
                <br />
                MSc in Nursing Education (KIU, SL),
                <br />
                BSc. (Hons) in Nursing (University of Ruhuna, SL)
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}
            sx={{
              marginTop: "3%",
              marginLeft: "6%",
            }}
          >
            <Box
              sx={{
                width: "200px",
                height: "200px",
              }}
            >
              <LazyLoadImage
                src="https://kiu-website.s3.ap-southeast-1.amazonaws.com/Resource/1721388184383_Caroline.jpg"
                alt="researcher"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </Box>
            <Typography className="res-quote-text-s1" sx={{ fontWeight: 600 }}>
              CAROLINE ANASTASIA FERNANDO
            </Typography>
            <p className="res-quote-text-s2">
              TECHNOLOGY TRANSFER MANAGER / LECTURER
            </p>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            sx={{
              marginTop: "3%",
              marginLeft: "6%",
            }}
          >
            <div className="res-quote">
              <img
                alt="KIU Uni"
                src={quote}
                className="blockQ"
              ></img>
            </div>
            <div className="res-quote-text">
              <Typography
                variant="h5"
                sx={{
                  fontFamily: "Kanit, sans-serif",
                }}
              >
                I strive to unlock the Genetic Code to break free from addiction
                and create a path to recovery."
              </Typography>
              <Typography className="res-foc-txt">
                Research Focus: Unlocking the Genetic Code for addiction
                recovery.
              </Typography>
              <Typography
                sx={{
                  fontSize: "13px",
                  textAlign: "end",
                  fontFamily: "Montserrat, sans-serif",
                }}
              >
                BSc. (Hons) Medical Laboratory Sciences (General Sir John
                Kotelawala Defence University, SL)
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}
            sx={{
              marginTop: "3%",
              marginLeft: "6%",
            }}
          >
            <Box
              sx={{
                width: "200px",
                height: "200px",
              }}
            >
              <LazyLoadImage
                src="https://kiu-website.s3.ap-southeast-1.amazonaws.com/Resource/1721388367427_Amal.jpg"
                alt="researcher"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </Box>
            <Typography className="res-quote-text-s1" sx={{ fontWeight: 600 }}>
              AMAL SUDARAKA SAMARASINGHE
            </Typography>
            <p className="res-quote-text-s2">ASSISTANT LECTURER</p>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            sx={{
              marginTop: "3%",
              marginLeft: "6%",
            }}
          >
            <div className="res-quote">
              <img
                alt="KIU Uni"
                src={quote}
                className="blockQ"
              ></img>
            </div>
            <div className="res-quote-text">
              <Typography
                variant="h5"
                sx={{
                  fontFamily: "Kanit, sans-serif",
                }}
              >
                In a world full of impossibilities, I delve into the roots of
                sustainable food and agriculture, nurturing a greener, more
                bountiful world through innovation and dedication”.
              </Typography>
              <Typography className="res-foc-txt">
                Research Focus: Sustainable food and agriculture for a greener
                world.
              </Typography>
              <Typography
                sx={{
                  fontSize: "13px",
                  textAlign: "end",
                  fontFamily: "Montserrat, sans-serif",
                }}
              >
                BSc. (Hons) Food Science and Technology (University of
                Peradeniya, SL)
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}
            sx={{
              marginTop: "3%",
              marginLeft: "6%",
            }}
          >
            <Box
              sx={{
                width: "200px",
                height: "200px",
              }}
            >
              <LazyLoadImage
                src="https://kiu-website.s3.ap-southeast-1.amazonaws.com/Resource/1721388425900_Fabiola.jpg"
                alt="researcher"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </Box>
            <Typography className="res-quote-text-s1" sx={{ fontWeight: 600 }}>
              ANTONETTE MELIZA FABIOLA FERNANDO
            </Typography>
            <p className="res-quote-text-s2">RESEARCH ASSISTANT</p>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            sx={{
              marginTop: "3%",
              marginLeft: "6%",
            }}
          >
            <div className="res-quote">
              <img
                alt="KIU Uni"
                src={quote}
                className="blockQ"
              ></img>
            </div>
            <div className="res-quote-text">
              <Typography
                variant="h5"
                sx={{
                  fontFamily: "Kanit, sans-serif",
                }}
              >
                By investigating advanced technological interventions, I strive
                to gain insights into how my research reverberates and shapes
                our understanding of genetic intricacies.”
              </Typography>
              <Typography className="res-foc-txt">
                Research Focus: Advanced technological interventions in genetic
                research.
              </Typography>
              <Typography
                sx={{
                  fontSize: "13px",
                  textAlign: "end",
                  fontFamily: "Montserrat, sans-serif",
                }}
              >
                BSc. (Hons) Biotechnology (University of Northumbria, UK),
                <br />
                Advanced Diploma in Pharmaceutical Sciences (PATHE Academy, SL)
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}
            sx={{
              marginTop: "3%",
              marginLeft: "6%",
            }}
          >
            <Box
              sx={{
                width: "200px",
                height: "200px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <LazyLoadImage
                src="https://kiu-website.s3.ap-southeast-1.amazonaws.com/Resource/1721388482425_Janitha.jpg"
                alt="researcher"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </Box>
            <Typography className="res-quote-text-s1" sx={{ fontWeight: 600 }}>
              T. M. JANITHA CHARUNI
            </Typography>
            <p className="res-quote-text-s2">RESEARCH ASSISTANT</p>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            sx={{
              marginTop: "3%",
              marginLeft: "6%",
            }}
          >
            <div className="res-quote">
              <img
                alt="KIU Uni"
                src={quote}
                className="blockQ"
              ></img>
            </div>
            <div className="res-quote-text">
              <Typography
                variant="h5"
                sx={{
                  fontFamily: "Kanit, sans-serif",
                }}
              >
                Harnessing the power to enlighten the possible pharmacological
                practices and investigate clinical neuro-psychological
                insights”.
              </Typography>
              <Typography className="res-foc-txt">
                Research Focus: Pharmacological practices and clinical
                neuro-psychological insights.
              </Typography>
              <Typography
                sx={{
                  fontSize: "13px",
                  textAlign: "end",
                  fontFamily: "Montserrat, sans-serif",
                }}
              >
                BSc. (Hons) Psychology (KIU, SL),
                <br />
                Diploma in Counselling (University of Kelaniya, SL),
                <br />
                Diploma in Pharmacy Management and Practice (Aquinas College and
                Higher Studies, SL)
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Container>

      <div className="fac-horizontal-divider"></div>

      <Container id="researchSection">
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              marginLeft: "6%",
            }}
          >
            <Typography
              variant="h3"
              sx={{
                fontFamily: "Kanit, sans-serif",
                fontSize: "35px",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              Explore the research impacts led by our Faculty Deans
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container id="researchSection">
        <Grid
          container="container"
          spacing={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              marginTop: "3%",
              marginLeft: "6%",
            }}
          >
            <Accordion
              id="researchSection"
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
            >
              <AccordionSummary
                id="controlled-panel-header-4"
                aria-controls="controlled-panel-content-4"
                expandIcon={
                  <Icon fontSize="1.25rem" icon="tabler:chevron-down" />
                }
              >
                <Typography sx={deanStyle}>
                  Prof. S. S. P. Warnakulasuriya: Honorary Dean of the Faculty
                  of Nursing{" "}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    color: "text.secondary",
                    cursor: "pointer",
                    mb: 2,
                    "&:hover": {
                      color: "#CECECE", // Change the color as needed
                    },
                  }}
                  onClick={() =>
                    window.open("https://doi.org/10.1016/j.pain.2013.02.008")
                  }
                >
                  Coggon, D., Ntani, G., Palmer, K. T., Felli, V. E., Harari,
                  R., Barrero, L. H., Felknor, S. A., Gimeno, D., Cattrell, A.,
                  Serra, C., Bonzini, M., Solidaki, E., Merisalu, E., Habib, R.
                  R., Sadeghian, F., Kadir, M. M., Warnakulasuriya, S. S. P.,
                  Matsudaira, K., Nyantumbu, B., Sim, M. R. & Gray, A. (2013)
                  ‘Disabling musculoskeletal pain in working populations: Is it
                  the job, the person, or the culture?’, PAIN, 154 (6), pp.
                  856-863.
                </Typography>

                <Typography
                  sx={{
                    color: "text.secondary",
                    cursor: "pointer",
                    mb: 2,
                    "&:hover": {
                      color: "#CECECE", // Change the color as needed
                    },
                  }}
                  onClick={() =>
                    window.open(
                      "https://doi.org/10.1371/annotation/3faf76e5-f73e-427f-9d60-8f94939b0f7e"
                    )
                  }
                >
                  Coggon, D., Ntani, G., Palmer, K. T., Felli, V. E., Harari, R.
                  et al. (2012) ‘The CUPID (Cultural and Psychosocial Influences
                  on Disability) Study: Methods of Data Collection and
                  Characteristics of Study Sample’, PLOS ONE, 7 (10).
                </Typography>

                <Typography
                  sx={{
                    color: "text.secondary",
                    cursor: "pointer",
                    mb: 2,
                    "&:hover": {
                      color: "#CECECE", // Change the color as needed
                    },
                  }}
                  onClick={() =>
                    window.open("https://doi.org/10.1093/occmed/kqs057")
                  }
                >
                  Warnakulasuriya, S. S. P., Peiris-John, R. J., Coggon, D.,
                  Ntani, G., Sathiakumar, N. & Wickremasinghe, A. R. (2012)
                  ‘Musculoskeletal pain in four occupational populations in Sri
                  Lanka’, Occupational Medicine, 62 (4), pp.269–272.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              id="researchSection"
              expanded={expanded === "panel6"}
              onChange={handleChange("panel6")}
              sx={{ mt: 1 }}
            >
              <AccordionSummary
                id="controlled-panel-header-6"
                aria-controls="controlled-panel-content-6"
                expandIcon={
                  <Icon fontSize="1.25rem" icon="tabler:chevron-down" />
                }
              >
                <Typography sx={deanStyle}>
                  Dr. Anoma Edirimanna: Dean of the Faculty of Management
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    color: "text.secondary",
                    cursor: "pointer",
                    mb: 2,
                    "&:hover": {
                      color: "#CECECE", // Change the color as needed
                    },
                  }}
                  onClick={() =>
                    window.open("https://doi.org/10.24191/ajue.v18i1.17252")
                  }
                >
                  Perera, A. A. S., Rahmat, A.K., Khatibi, A. & Azam, S.M.F.
                  (2022). Reliability Assessment of Indicators Measuring the
                  Impact of Enterprise Risk Management on Performance of Higher
                  Education Institutions in Sri Lanka. Asian Journal of
                  University Education (AJUE). Volume 18, Number 1, January
                  2022. pp (300 – 321)
                </Typography>

                <Typography
                  sx={{
                    color: "text.secondary",
                    cursor: "pointer",
                    mb: 2,
                    "&:hover": {
                      color: "#CECECE", // Change the color as needed
                    },
                  }}
                  onClick={() =>
                    window.open(
                      "http://hkjoss.com/index.php/journal/article/view/474/470"
                    )
                  }
                >
                  Perera, A. A. S., Rahmat, A.K., Khatibi, A. & Azam, S.M.F.
                  (2021). The impact of Enterprise Risk Management on
                  Performance of Higher education institutions in Sri Lanka.
                  Hong Kong Journal of Social Sciences (HKJOSS). No 58
                  Autumn/Winter 2021. pp (252 – 265).
                </Typography>

                <Typography
                  sx={{
                    color: "text.secondary",
                    cursor: "pointer",
                    mb: 2,
                    "&:hover": {
                      color: "#CECECE", // Change the color as needed
                    },
                  }}
                  onClick={() =>
                    window.open(
                      " https://www.ijern.com/journal/2020/October- 2020/14.pdf "
                    )
                  }
                >
                  Perera, A. A. S., Rahmat, A.K., Khatibi, A. & Azam, S.M.F.
                  (2020). Review of Literature: Implementation of Enterprise
                  Risk Management into Higher Education. International Journal
                  of Education and Research. 8(10). pp (155-172)
                </Typography>

                <Typography
                  sx={{
                    color: "text.secondary",
                    cursor: "pointer",
                    mb: 2,
                    "&:hover": {
                      color: "#CECECE", // Change the color as needed
                    },
                  }}
                  onClick={() =>
                    window.open(
                      "http://dx.doi.org/10.29322/IJSRP.9.07.2019.p9130"
                    )
                  }
                >
                  Perera, A. A. S. (2019). Enterprise Risk Management –
                  International Standards and Frameworks. International Journal
                  of Scientific and Research Publications. 9(7). pp (211-217)
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              id="researchSection"
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
              sx={{ mt: 1 }}
            >
              <AccordionSummary
                id="controlled-panel-header-2"
                aria-controls="controlled-panel-content-2"
                expandIcon={
                  <Icon fontSize="1.25rem" icon="tabler:chevron-down" />
                }
              >
                <Typography sx={deanStyle}>
                  Dr. Kanthi Hettigoda: Honorary Dean of the Faculty of
                  Behavioural Sciences{" "}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    color: "text.secondary",
                    cursor: "pointer",
                    mb: 2,
                    "&:hover": {
                      color: "#CECECE", // Change the color as needed
                    },
                  }}
                  onClick={() =>
                    window.open("https://doi.org/10.4038/kjms.v5i1.71")
                  }
                >
                  Weerasinghe, G. D. D. B., Patabendige, P. N. M., Thuduwage, S.
                  V. R., Arumapperumachchi, P. M., Gamage, C. K. W., Hettigoda,
                  K. (2023) ‘The Cross-Cultural Adaptation of The Schutte
                  Self-report Emotional Intelligence Test (SSEIT) For Use Among
                  Nursing Students In A Defence University, Sri Lanka’, KDU
                  Journal of Multidisciplinary Studies, 5 (1).
                </Typography>
                <Typography
                  sx={{
                    color: "text.secondary",
                    cursor: "pointer",
                    mb: 2,
                    "&:hover": {
                      color: "#CECECE", // Change the color as needed
                    },
                  }}
                  onClick={() =>
                    window.open(
                      "http://192.248.104.6/bitstream/handle/345/2062/dss012.pdf?sequence=3&isAllowed=y"
                    )
                  }
                >
                  Hettigoda, K. & Hamersley, R. (2019) ‘Resilience Predicts
                  Military Performance and Succession: A Predictive Validity
                  Study of Resilience Inventory For Military (RIM)’. In
                  Proceedings of 12th International Research Conference 2019,
                  KDU.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              id="researchSection"
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
              sx={{ mt: 1 }}
            >
              <AccordionSummary
                id="controlled-panel-header-1"
                aria-controls="controlled-panel-content-1"
                expandIcon={
                  <Icon fontSize="1.25rem" icon="tabler:chevron-down" />
                }
              >
                <Typography sx={deanStyle}>
                  Akila Randika Jayamaha (PhD reading): Dean of the Faculty of
                  Graduate Studies{" "}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    color: "text.secondary",
                    cursor: "pointer",
                    mb: 2,
                    "&:hover": {
                      color: "#CECECE", // Change the color as needed
                    },
                  }}
                  onClick={() =>
                    window.open("https://doi.org/10.1007/s11136-022-03270-4")
                  }
                >
                  Jayamaha, A. R., Herath, N. D. M., Dharmarathna, N. D.,
                  Sandakumari, H. S., Ranadeva, N. D. K., Fernando, M. M.,
                  Samarakoon, N. A. W., Amarabandu, P. N., Senanayake, B.,
                  Darshana, T., Renuka, N., Samarasinghe, K. L. & Fernando, N.
                  (2022) ‘Health-related quality of life in patients with
                  substance use disorders enrolled to the residential treatment
                  in Sri Lanka: a retrospective cross-sectional study’, Quality
                  of Life Research.
                </Typography>

                <Typography
                  sx={{
                    color: "text.secondary",
                    cursor: "pointer",
                    mb: 2,
                    "&:hover": {
                      color: "#CECECE", // Change the color as needed
                    },
                  }}
                  onClick={() =>
                    window.open("https://doi.org/10.1177/11782218221100823")
                  }
                >
                  Jayamaha, A. R., Dharmarathna, N. D., Herath, N. D., Ranadeva,
                  N. D., Fernando, M. M., Samarasinghe, K. L., Amarabandu, P.
                  N., Senanayake, B., Darshana, T., Renuka, N., Rajapakse, I.
                  H., Gunasekara, C. P., Meegoda, L. & Fernando, N. (2022) ‘The
                  Pattern of Substance Use and Characteristics of the
                  Individuals Enrolled in Residential Treatment at Selected
                  Rehabilitation Centers in Sri Lanka: A Descriptive
                  Cross-Sectional Study’, Substance Abuse: Research and
                  Treatment, 16, pp.1-17.
                </Typography>

                <Typography
                  sx={{
                    color: "text.secondary",
                    cursor: "pointer",
                    mb: 2,
                    "&:hover": {
                      color: "#CECECE", // Change the color as needed
                    },
                  }}
                  onClick={() =>
                    window.open("https://doi.org/10.1080/10509674.2022.2062519")
                  }
                >
                  Jayamaha, A. R., Herath, H. M. N. D. M., Dharmarathna, H. N.
                  N. D., Ranadeva, N. D. K., Amarabandu, P. N., Senanayake, B.,
                  ... & Fernando, S. S. N. (2022) ‘Implementing therapeutic
                  community as a rehabilitation intervention for the imprisoned
                  narcotic drug offenders with substance use disorder: special
                  reference to Sri Lanka’, Journal of Offender Rehabilitation,
                  61 (4), pp. 188-206.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              id="researchSection"
              expanded={expanded === "panel5"}
              onChange={handleChange("panel5")}
              sx={{ mt: 1 }}
            >
              <AccordionSummary
                id="controlled-panel-header-5"
                aria-controls="controlled-panel-content-5"
                expandIcon={
                  <Icon fontSize="1.25rem" icon="tabler:chevron-down" />
                }
              >
                <Typography sx={deanStyle}>
                  Dr. R. M. S. Rathnayake: Honorary Dean of the Faculty of
                  Computer Science & Engineering{" "}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    color: "text.secondary",
                    cursor: "pointer",
                    mb: 2,
                    "&:hover": {
                      color: "#CECECE", // Change the color as needed
                    },
                  }}
                  onClick={() =>
                    window.open("https://doi.org/10.1145/2503210.2503269")
                  }
                >
                  Jayasena, S., Amarasinghe, S., Abeyweera, A., Amarasinghe, G.,
                  De Silva, H., Rathnayake, S., Meng, X. & Liu, Y. (2013)
                  ‘Detection of false sharing using machine learning’. In SC
                  '13: Proceedings of the International Conference on High
                  Performance Computing, Networking, Storage and Analysis.
                </Typography>

                <Typography
                  sx={{
                    color: "text.secondary",
                    cursor: "pointer",
                    mb: 2,
                    "&:hover": {
                      color: "#CECECE", // Change the color as needed
                    },
                  }}
                  onClick={() =>
                    window.open("https://doi.org/10.1109/ICPP.2017.43")
                  }
                >
                  Rathnayake, S., Loghin, D. & Teo, Y. M. (2017) “CELIA:
                  Cost-Time Performance of Elastic Applications on Cloud’. 46th
                  International Conference on Parallel Processing (ICPP), pp.
                  342-351.
                </Typography>

                <Typography
                  sx={{
                    color: "text.secondary",
                    cursor: "pointer",
                    mb: 2,
                    "&:hover": {
                      color: "#CECECE", // Change the color as needed
                    },
                  }}
                  onClick={() =>
                    window.open("https://doi.org/10.1145/3409390.3409409")
                  }
                >
                  Rathnayake, S., Ramapantulu, L. & Teo, Y. M. (2020)
                  ‘Characterizing the Cost-Accuracy Performance of Cloud
                  Applications’. In Workshop Proceedings of the 49th
                  International Conference on Parallel Processing.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              id="researchSection"
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
              sx={{ mt: 1 }}
            >
              <AccordionSummary
                id="controlled-panel-header-3"
                aria-controls="controlled-panel-content-3"
                expandIcon={
                  <Icon fontSize="1.25rem" icon="tabler:chevron-down" />
                }
              >
                <Typography sx={deanStyle}>
                  Mrs.Nadeeka Ranadeva: Dean of the Faculty of Health Sciences{" "}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    color: "text.secondary",
                    cursor: "pointer",
                    mb: 2,
                    "&:hover": {
                      color: "#CECECE", // Change the color as needed
                    },
                  }}
                  onClick={() =>
                    window.open(
                      "https://www.researchgate.net/publication/376621943_Roles_of_NS1_Protein_in_Flavivirus_Pathogenesis"
                    )
                  }
                >
                  1. DR Perera, ND Ranadeva, K Sirisena, KJ Wijesinghe; Roles of
                  NS1 Protein in flavivirus pathogenesis, ACS Infectious
                  Diseases 10 (1), 20-56
                </Typography>

                <Typography
                  sx={{
                    color: "text.secondary",
                    cursor: "pointer",
                    mb: 2,
                    "&:hover": {
                      color: "#CECECE", // Change the color as needed
                    },
                  }}
                  onClick={() =>
                    window.open(
                      "https://www.researchgate.net/publication/365990771_Perceived_effectiveness_of_theraputic_community_model_as_a_rehabilitation_approach_for_the_individuals_with_substance_use_disorder_Sri_Lanka"
                    )
                  }
                >
                  2. Dharmarathna, N. D., Jayamaha, A. R., Ranadeva, N. D. K.,
                  Rajapakse, H., Gunasekara, C., Fernando, N., & Meegoda, L.
                  (2023). Perceived effectiveness of therapeutic community model
                  as a rehabilitation approach for Sri Lankan individuals with
                  substance use disorder. Therapeutic Communities: The
                  International Journal of Therapeutic Communities.
                </Typography>

                <Typography
                  sx={{
                    color: "text.secondary",
                    cursor: "pointer",
                    mb: 2,
                    "&:hover": {
                      color: "#CECECE", // Change the color as needed
                    },
                  }}
                  onClick={() =>
                    window.open(
                      "https://www.researchgate.net/publication/364342186_Health-related_quality_of_life_in_patients_with_substance_use_disorders_enrolled_to_the_residential_treatment_in_Sri_Lanka_a_retrospective_cross-sectional_study"
                    )
                  }
                >
                  3. Jayamaha AR, Herath NDM, Dharmarathna ND, Sandakumari HS,
                  Ranadeva NDK, Fernando MM, Samarakoon NAW, Amarabandu PN,
                  Senanayake B, Darshana T, Renuka N, Samarasinghe KL, Fernando
                  N. Health-related quality of life in patients with substance
                  use disorders enrolled to the residential treatment in Sri
                  Lanka: a retrospective cross-sectional study. Qual Life Res.
                  2023 Feb;32(2):435-445. doi: 10.1007/s11136-022-03270-4. Epub
                  2022 Oct 16. PMID: 36245018.
                </Typography>

                <Typography
                  sx={{
                    color: "text.secondary",
                    cursor: "pointer",
                    mb: 2,
                    "&:hover": {
                      color: "#CECECE", // Change the color as needed
                    },
                  }}
                  onClick={() =>
                    window.open(
                      "https://www.researchgate.net/publication/359294643_Design_and_implementation_of_a_novel_pharmacogenetic_assay_for_the_identification_of_the_CYP2D610_genetic_variant"
                    )
                  }
                >
                  4. Ranadeva NDK, Sirisena ND, Wetthasinghe TK, Noordeen N,
                  Dissanayake VHW. Design and implementation of a novel
                  pharmacogenetic assay for the identification of the CYP2D6*10
                  genetic variant. BMC Res Notes. 2022 Mar 16;15(1):104. doi:
                  10.1186/s13104-022-05993-6. PMID: 35296326; PMCID: PMC8925205.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Container>

      <div className="fac-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              marginLeft: "6%",
            }}
          >
            <Typography
              variant="h3"
              sx={{
                fontFamily: "Kanit, sans-serif",
                fontSize: "35px",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              Research News
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container
        sx={{
          marginTop: "5%",
        }}
      >
        {news.map((item) => (
          <Card key={item.id} style={newsStyle}>
            <CardContent>
              <Grid container="container" spacing={2}>
                <Grid
                  item="item"
                  xs={12}
                  sm={12}
                  md={7}
                  lg={7}
                  xl={7}
                  sx={{
                    marginTop: "3%",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Kanit, sans-serif",
                      textAlign: "left",
                      fontSize: "25px",
                      fontWeight: "bold",
                    }}
                  >
                    {item.title}
                  </Typography>
                </Grid>

                <Grid
                  item="item"
                  xs={12}
                  sm={12}
                  md={3}
                  lg={3}
                  xl={3}
                  sx={{
                    marginTop: "5%",
                  }}
                >
                  {/* Add content for the second grid if needed */}
                </Grid>

                <Grid
                  item="item"
                  xs={12}
                  sm={12}
                  md={7}
                  lg={7}
                  xl={7}
                  sx={{
                    marginTop: "1%",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "15px",
                      color: "#717171 ",
                      fontFamily: "Montserrat, sans-serif",
                      fontStyle: "italic",
                    }}
                  >
                    {item.content}
                  </Typography>
                  <Typography
                    sx={{
                      textAlign: "end",
                      mt: 3,
                      fontWeight: "900",
                    }}
                  >
                    {formatDate(item.date)}
                  </Typography>
                </Grid>

                <Grid
                  item="item"
                  xs={12}
                  sm={12}
                  md={3}
                  lg={3}
                  xl={3}
                  sx={{
                    marginTop: "3%",
                    marginLeft: "5%",
                  }}
                >
                  <Typography>
                    <img
                      alt="KIU Uni"
                      src={item.titleImage}
                      className="res-researcher-img"
                      style={{
                        width: "100%",
                        height: "70%",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    />
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        ))}
      </Container>

      <div className="footer-div">
        <Footer />
      </div>
    </div>
  );
};

export default Research;
