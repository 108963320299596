import React from "react";
import { Button, Container, Grid, Typography } from "@mui/material";
import "./Ugcrs.css";
import BAPDF from "../../brochure/sample.pdf";
import DSimg from "../../assets/EngFlip1.jpg";

const DS = ({ onClose }) => {
  const buttonStyles1 = {
    backgroundColor: "transparent",
    border: "2px solid #44DA18", // Blue outline
    color: "#44DA18", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const buttonStyles2 = {
    backgroundColor: "transparent",
    border: "2px solid #00AAFD", // Blue outline
    color: "#00AAFD", // Blue text color
  };
  const buttonStyles3 = {
    backgroundColor: "transparent",
    border: "2px solid #FF0B00", // Blue outline
    color: "#FF0B00", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const handleDownloadBrochure = () => {
    const link = document.createElement("a");
    link.href = BAPDF;
    link.download = "Bachelor of Management Honours in Business Analytics.pdf";
    link.click();
  };
  return (
    <div>
      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography
              variant="h2"
              sx={{
                fontFamily: "Kanit, sans-serif",
                fontSize: "35px",
                marginTop: "5%",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              BSc Honors in Data Science 
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            sx={{
              // marginLeft: '6%'
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography className="ug-crs-des1">
              Data Science is recognized as an emerging field of science and
              composed of Big Data and data driven technologies. The field of
              Data Science causes a transformational effect on research and
              industry domains. Data Science specialization aims at teaching
              students about data science principles and how to apply them to
              real world problems. The curriculum also pays significant
              attention to data analytics, statistical methods, mathematics, and
              Computer Science fundamentals. <br />
              Students who follow this degree programme will learn how to
              interact with data at all stages of an investigation and will be
              able to work within a team environment. Key competencies that
              students will gain are; computational and statistical thinking,
              mathematical foundations, model building and assessment,
              algorithms and software foundation, data curation, and knowledge
              transference – communication and responsibility. <br />
              Data Science graduates have a variety of career opportunities,
              both nationally and internationally and upon completion of the
              degree, they will be qualified to work in Data Science roles that
              range from general to specialized. Possible career opportunities
              are Data Scientist, Data Analyst, Data Science Consultant, Big
              Data Engineer, and Machine Learning Engineer. The depth and
              breadth of knowledge in this area of study are increasingly
              attractive to employers and would lead to careers not only as Data
              Scientist professionals but also in wider areas of the IT
              industry. <br />
              In addition, KIU undergraduates have the opportunity to take part
              in industrial placements in leading companies which provide
              valuable industry and research experience.
            </Typography>
          </Grid>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              alt="KIU Uni"
              src={DSimg}
              className="about-vc-img"
              alt="Data Science"
              style={{
                width: "100%",
                height: "50%",
                padding: "1em",
              }}
            />
          </Grid>
        </Grid>
      </Container>

      <div className="ug-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des2">
              <p>
                Level: SLQF 6
                <br />
                Duration: Semester 8 (4 Years)
                <br />
                Credit Points: 129
                <br />
                Intakes: March and September 
              </p>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des3">
              <h3>Career Opportunities</h3>
              <ol>
                <li>Data Scientist</li>
                <li>Data Analyst</li>
                <li>Data Science Consultant</li>
                <li>Big Data Engineer</li>
                <li>Machine learning Engineer</li>
              </ol>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des4">
              <h5>Entry Requirements</h5>

              <ul>
                <li>
                  At least three (3) simple passes (S) in Physical Sciences
                  stream or Engineering Technology stream in one and the same
                  sitting at the G.C.E (Advanced Level) Examination conducted by
                  the Department of Examinations of Sri Lanka or equivalent
                  qualification.
                </li>
                Or
                <li>
                  At least three (3) simple passes (S) in any stream in one and
                  the same sitting at the G.C.E (Advanced Level) Examination
                  conducted by the Department of Examinations of Sri Lanka or
                  equivalent qualification with a Credit pass (C) in Mathematics
                  at the G.C.E (Ordinary Level) Examination conducted by the
                  Department of Examination of Sri Lanka or equivalent
                  qualification with a bridging programme approved by the
                  Specified Authority.
                </li>
                Or
                <li>
                  At least three (3) simple passes(S) in any stream in one and
                  the same sitting with a simple pass(S) for Information &
                  Communication Technology, in any attempt at the G.C.E.
                  (Advanced Level) Examination conducted by the Department of
                  Examinations of Sri Lanka or equivalent qualification and a
                  Credit pass(C) in Mathematics at the G.C.E. (Ordinary Level)
                  Examination conducted by the Department of Examinations of Sri
                  Lanka or equivalent qualification.{" "}
                </li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="ug-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des5">
              <h5>Program Structure</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des6">
              <h5>Year 1</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des7">
              <h5>Semester 1</h5>
              <ul>
                <li>Introduction to Computer Systems</li>
                <li>Computer Architecture</li>
                <li>Fundamentals of Programming</li>
                <li>Academic Practices and Grooming</li>
                <li>Discrete Mathematics and Graph Theory</li>
              </ul>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des8">
              <h5>Semester 2</h5>

              <ul>
                <li>Data Structures and Algorithms</li>
                <li>Object-Oriented Programming</li>
                <li>Database systems</li>
                <li>Data Communication</li>
                <li>Linear Algebra and Vector Analysis</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des6">
              <h5>Year 2</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des7">
              <h5>Semester 1</h5>
              <ul>
                <li>Introduction to Data Science</li>
                <li>Data Privacy and Security</li>
                <li>Systems Analysis and Design</li>
                <li>Introduction to Software Engineering</li>
                <li>Calculus</li>
              </ul>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des8">
              <h5>Semester 2</h5>

              <ul>
                <li>Artificial Intelligence</li>
                <li>Operating Systems and System Administration</li>
                <li>Statistics for Data Science</li>
                <li>Data Acquisition and Management</li>
                <li>Cryptography and Information Theory</li>
                <li>Data Visualization</li>
                <li>
                  Career Planning and Employability Skills Development – Seminar
                </li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des6">
              <h5>Year 3</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des7">
              <h5>Semester 1</h5>
              <ul>
                <li>Research Methods for Computing</li>
                <li>IT Professionalism and Practice</li>
                <li>Data Mining and Data Warehousing</li>
                <li>Introduction to Machine Learning</li>
                <li>Data Science Group Project</li>
              </ul>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des8">
              <h5>Semester 2</h5>

              <ul>
                <li>Business Analytics</li>
                <li>Business Intelligence</li>
                <li>Bioinformatics</li>
                <li>Work Based Enterprise Placement</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des6">
              <h5>Year 4</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des7">
              <h5>Semester 1</h5>
              <ul>
                <li>Final Year Individual Project</li>
                <li>Big Data Systems</li>
                <li>Distributed Systems</li>
                <li>Introduction to Blockchain Technology</li>
                <li>Data Science Project Management</li>
                <li>Internet of Things</li>
                <li>Web Technologies</li>
                <li>Knowledge-based Systems</li>
                <li>Database Internals</li>
                <li>Introduction to Natural Language Processing</li>
              </ul>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des8">
              <h5>Semester 2</h5>

              <ul>
                <li>Advanced Machine Learning</li>
                <li>Data Modeling and Simulation</li>
                <li>Big Data Analytics</li>
                <li>Advanced Statistics</li>
                <li>Information Retrieval</li>
                <li>Natural Computing</li>
                <li>Stochastic Modeling</li>
                <li>Entrepreneurship</li>
                <li>Compiler Theory</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="ug-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des4">
              <h5>
                Graduates obtaining the Bachelor of Science Honours in Data
                Science programme should be able to:
              </h5>

              <ul>
                <li>
                  apply knowledge of data science and computing fundamentals,
                  mathematics, science, and data science domain knowledge
                  appropriate for the computing specialization to the
                  abstraction and conceptualization of computing models from
                  defined problems and requirements;
                </li>
                <li>
                  apply appropriate techniques, resources, and computing tools
                  to Data Science related practical applications, with an
                  understanding of the limitations;
                </li>
                <li>
                  design creative solutions and evaluate those for complex data
                  science and computing problems, and design and evaluate
                  systems, components, or processes that meet specified needs
                  with appropriate consideration for public health and safety,
                  cultural, societal, and environmental considerations;
                </li>
                <li>
                  communicate effectively with the data science and computing
                  community and with society at large, such as being able to
                  comprehend and write effective reports and design
                  documentation, make effective presentations, and give and
                  receive clear instructions;
                </li>
                <li>
                  create networks with people and organizations and function
                  smoothly and effectively in society;{" "}
                </li>
                <li>
                  function effectively as an individual, and as a member or
                  leader in diverse teams and in multi-disciplinary settings;
                </li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="ug-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <a
              href="https://erpv2std.kiu.lk/online-registration/29"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button style={buttonStyles1}>Apply</Button>
            </a>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <Button
              disabled
              style={buttonStyles2}
              onClick={handleDownloadBrochure}
            >
              Download Brochure
            </Button>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <Button style={buttonStyles3} onClick={onClose}>
              Close
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default DS;
