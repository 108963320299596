import { Button, Container, Grid } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import "./Faculty.css";

const FOEL = () => {
  const buttonStyles1 = {
    backgroundColor: "transparent",
    border: "2px solid #f21111", // Blue outline
    color: "#f21111", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const navigate = useNavigate();
  const handleBtnClick = (path) => {
    navigate(path);
  };
  return (
    <div>
      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 className="fac-title">Faculty Of Education & Languages</h3>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <p className="fac-descrip">
              With the increase of opportunities in the education sector,
              Education and Languages have become demanding areas of studies.
              The Faculty of Education & Languages strives to provide
              opportunities and an inspiring learning atmosphere for the
              students to develop their language competency. The faculty offers
              education courses which are aimed at those who wish to pursue a
              career in the teaching profession and enhance their professional
              qualification in the relevant field.
            </p>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          {/* <Grid item="item" xs={12} sm={12} md={6} lg={6} xl={6}>
                        <h3 className='fac-dean-hed'>Dean</h3>
                        <p className='fac-descrip1'>
                            Prof. Koliya Pulasinghe<br/>BSc Eng. (Hons) in Computer Systems & Engineering,
                                University of Moratuwa, Sri Lanka, 1996<br/>
                            Contact: +94 77 123 4567<br/>
                            Email: abc@gmail.com
                        </p>
                    </Grid> */}

          <Grid item="item" xs={12} sm={12} md={6} lg={6} xl={6}>
            <h3 className="fac-hod-hed">Head of Department</h3>
            <p className="fac-descrip1">
              D.Nimesha Amarasooriya
              <br />
              PhD( reading) MA (Linguistics)
              <br />
              Contact: 0772106110
              <br />
              Email: nimesha@kiu.ac.lk
            </p>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 className="fac-depts-hed">Departments</h3>
            <ul className="fac-depts-list">
              <li className="fac-depts-list-item">Department Of Languages</li>
            </ul>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 className="fac-fas-hed">The Faculty Offers</h3>
            <p className="fac-descrip1">
              <strong>English Language Programmes: </strong>
              <br />
              The English language programmes have been formulated for the
              development of undergraduates. The competency of English is of
              paramount importance to career mobility. Hence, to equip them with
              the competency that is required to enhance their employability,
              ‘Elementary’, ‘Intermediate’ and ‘Advanced’ certificate programmes
              have been conducted to improve the English language competency of
              the students.
              <br />
              <br />
              <strong>Japanese Language Programmes: </strong>
              <br />
              The Department of Languages expects to meet the higher demand for
              employment opportunities in Japan through its Japanese Language
              programmes. These programmes are designed to provide the students
              with expertise and knowledge at the N3 level and enable Sri Lankan
              students to obtain a great opportunity to pursue their career
              dreams in Japan.
              <br />
              <br />
            </p>
          </Grid>
        </Grid>
      </Container>

      {/* <Container>
                <Grid container="container" spacing={2}>
                    <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Button style={buttonStyles1} onClick={() => handleBtnClick('/WeOffer')}>View Courses</Button>
                    </Grid>
                </Grid>
            </Container> */}
    </div>
  );
};

export default FOEL;
