import React from "react";
import styled, { css, keyframes } from "styled-components";

const partnerS = () => {
  const row1 = [
    "https://www.dpeducation.lk/en/assets/images/DP%20education%20logo%202x.png",
    "https://www.roshana.com.au/images/Roshana-Care-Group.jpg",
    "https://www.greencross.lk/wp-content/uploads/2021/09/greencross-2.jpg",
    "https://www.dilmahtea.com/images/logo.jpg",
    "https://www.cipmlk.org/wp-content/uploads/2020/12/cipm21-web-logo.svg",
    "https://kiu-website.s3.ap-southeast-1.amazonaws.com/Resource/1723778433096_85283c994fd1439bf7ebd47ce0fd205c.jpeg",
  ];

  const row2 = [
    "https://mediccom.lk/assets/images/mediccom/slidertxt.png",
    "https://www.ac.lk/sites/default/files/learn-header-logo_0_2.png",
    "https://www.aicpa-cima.com/static/media/aicpa-cima-logo.2028af8b.svg",
    "https://cookie-cdn.cookiepro.com/logos/d5dea32d-f02f-4718-b495-77bc7b42e7ca/fa60271f-47b0-44ce-be2e-cf08e4c6211b/995c0012-f8fa-4f41-908c-30daa6a9ff06/acu-logo_rgb.png",
    "https://sp-ao.shortpixel.ai/client/to_webp,q_glossy,ret_img,w_219/https://www.ceyloncoconutcompany.com/wp-content/uploads/2020/11/header-logo.png",
  ];

  return (
    <div>
      <Wrapper>
        <Text>Meet Our Partners</Text>
        <Note>They are also a part of KIU sucess story</Note>
        <Marquee>
          <MarqueeGroup>
            {row1.map((el, index) => (
              <ImageGroup key={index}>
                <Image src={el} alt={"KIU Uni"} />
              </ImageGroup>
            ))}
          </MarqueeGroup>
          <MarqueeGroup>
            {row1.map((el, index) => (
              <ImageGroup key={index}>
                <Image src={el} alt={"KIU Uni"} />
              </ImageGroup>
            ))}
          </MarqueeGroup>
        </Marquee>
        <Marquee>
          <MarqueeGroup2>
            {row2.map((el, index) => (
              <ImageGroup key={index}>
                <Image src={el} alt={"KIU Uni"} />
              </ImageGroup>
            ))}
          </MarqueeGroup2>
          <MarqueeGroup2>
            {row2.map((el, index) => (
              <ImageGroup key={index}>
                <Image src={el} alt={"KIU Uni"} />
              </ImageGroup>
            ))}
          </MarqueeGroup2>
        </Marquee>
      </Wrapper>
    </div>
  );
};

export default partnerS;

const Wrapper = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Text = styled.div`
  font-size: 40px;
  font-family: "Kanit", sans-serif;
  font-weight: 700;
  margin-bottom: 10px;
  color: #02203c;

  @media (max-width: 600px) {
    font-size: 24px;
  }

  @media (min-width: 601px) and (max-width: 960px) {
    font-size: 30px;
  }

  @media (min-width: 961px) and (max-width: 1280px) {
    font-size: 36px;
  }

  @media (min-width: 1281px) and (max-width: 1920px) {
    font-size: 38px;
  }

  @media (min-width: 1921px) {
    font-size: 40px;
  }
`;

const Note = styled.div`
  font-family: "Montserrat", sans-serif;
  font-weight: 200;
  margin-bottom: 40px;
  color: #7c8e9a;
  font-family: "Montserrat", sans-serif;

  @media (max-width: 600px) {
    font-size: 14px;
  }

  @media (min-width: 601px) and (max-width: 960px) {
    font-size: 16px;
  }

  @media (min-width: 961px) and (max-width: 1280px) {
    font-size: 18px;
  }

  @media (min-width: 1281px) and (max-width: 1920px) {
    font-size: 20px;
  }

  @media (min-width: 1921px) {
    font-size: 22px;
  }
`;

const Marquee = styled.div`
  display: flex;
  width: 1200px;
  overflow: hidden;
  user-select: none;

  mask-image: linear-gradient(
    to right,
    hsl(0 0% 0% / 0),
    hsl(0 0% 0% / 1) 10%,
    hsl(0 0% 0% / 1) 90%,
    hsl(0 0% 0% / 0)
  );
`;

const scrollX = keyframes`
  from {
    left: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
`;

const common = css`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  white-space: nowrap;
  width: 100%;
  animation: ${scrollX} 30s linear infinite;
`;

const MarqueeGroup = styled.div`
  ${common}
`;
const MarqueeGroup2 = styled.div`
  ${common}
  animation-direction: reverse;
  animation-delay: -3s;
`;

const ImageGroup = styled.div`
  display: grid;
  place-items: center;
  width: clamp(10rem, 1rem + 40vmin, 30rem);
  padding: calc(clamp(10rem, 1rem + 30vmin, 30rem) / 10);
`;

const Image = styled.img`
  object-fit: contain;
  width: 100%;
  height: 100%;
  /* border: 1px solid black; */
  border-radius: 0.5rem;
  aspect-ratio: 16/9;
  padding: 5px 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
`;
