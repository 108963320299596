import { Box, Container, Grid, Typography } from "@mui/material";
import { motion, useAnimation, useInView } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import "react-lazy-load-image-component/src/effects/blur.css";
import ReactPlayer from "react-player";
import Head from "../components/Head";
import Header from "../components/Header";
import Footer from "../components/footer";
import "./About.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import VCIMG from "../assets/VC.jpeg";
import ITHO from "../assets/ITHO.jpg";
import SIR from "../assets/SIR.png";
import ADV from "../assets/ADV.jpg";

const About = () => {
  const [videos, setVideos] = useState([]);

  const ref = useRef(null);
  const isInView = useInView(ref);

  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView, mainControls]);

  useEffect(() => {
    // Fetch data from the API
    fetch("https://kiu.ac.lk/api/website/findLast4Students")
      .then((response) => response.json())
      .then((data) => {
        // Assuming the API response is an array of objects with "testamonial" and
        // "thumbnail" properties
        setVideos(data || []);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  return (
    <>
      <div className="about">
        <Header />

        <div className="head-div">
          <Head />
        </div>

        <div ref={ref}>
          <motion.div
            variants={{
              hidden: {
                opacity: 0,
                y: 75,
              },
              visible: {
                opacity: 1,
                y: 0,
              },
            }}
            initial="hidden"
            animate={mainControls}
            transition={{
              duration: 0.5,
              delay: 0.25,
            }}
          >
            <Container className="about-topic">
              <Grid container="container" spacing={2}>
                <Grid
                  item="item"
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  sx={{
                    marginLeft: "6%",
                  }}
                >
                  <Typography
                    variant="h3"
                    sx={{
                      fontFamily: "Kanit, sans-serif",
                      fontSize: "35px",
                      marginTop: "5%",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    Unveiling Our Journey Through Time: A Contemporary Look at
                    Our Evolution
                  </Typography>
                </Grid>
              </Grid>
            </Container>
          </motion.div>

          <motion.div
            variants={{
              hidden: {
                opacity: 0,
                y: 75,
              },
              visible: {
                opacity: 1,
                y: 0,
              },
            }}
            initial="hidden"
            animate={mainControls}
            transition={{
              duration: 0.5,
              delay: 0.25,
            }}
          >
            <Container className="about-history">
              <Grid container="container" spacing={2}>
                <Grid
                  item="item"
                  xs={12}
                  sm={12}
                  md={5}
                  lg={5}
                  xl={5}
                  sx={{
                    marginLeft: "6%",
                  }}
                >
                  <h1 className="about-past-h1">How We Started</h1>

                  <p className="about-past-p">
                    KIU is a leading non-state degree-awarding higher
                    educational institute established in 2009, accredited by the
                    Ministry of Higher Education and recognized by the
                    University Grants Commission of Sri Lanka under section 25 A
                    of the Universities Act, No.16 of 1978. In 2015, KIU
                    received degree-awarding status, with three initial degree
                    programmes, and has currently been expanded with fourteen
                    undergraduate and five postgraduate programmes. There are
                    eight faculties and seventeen departments. Furthermore, KIU
                    is one of the highly acclaimed universities in Sri Lanka
                    with pioneering degrees and world-class facilities. Our
                    courses provide students with a platform to launch their
                    dream career in their chosen field of study. We have created
                    an environment that stimulates the mind and encourages the
                    pursuit of knowledge.
                  </p>
                </Grid>

                <Grid
                  item="item"
                  xs={12}
                  sm={12}
                  md={5}
                  lg={5}
                  xl={5}
                  sx={{
                    marginLeft: "6%",
                  }}
                >
                  <h1 className="about-present-h1">How It's Going</h1>

                  <p className="about-present-p">
                    KIU is dedicated to serving as a gateway to developing
                    world-class professionals through global disciplines. Our
                    university offers an exceptional academic experience for its
                    students through the guidance of an accomplished team of
                    academic experts. In line with this commitment, our
                    institution stands with three distinct campuses: KIU Campus
                    (the main campus), Sakura campus, and the Kyo Ito Campus.
                    Currently, it provides diverse learning opportunities for
                    thousands of both local and international students. Our goal
                    is to attain the esteemed position of being Sri Lanka’s
                    foremost private-sector university, the top preference for
                    both students and for academics. We are committed to
                    upholding the highest standards of student excellence and
                    ensuring an unparalleled student experience that rivals the
                    best in the world.
                  </p>
                </Grid>
              </Grid>
            </Container>
          </motion.div>

          <div className="about-horizontal-divider"></div>

          <motion.div
            variants={{
              hidden: {
                opacity: 0,
                y: 75,
              },
              visible: {
                opacity: 1,
                y: 0,
              },
            }}
            initial="hidden"
            animate={mainControls}
            transition={{
              duration: 0.5,
              delay: 0.25,
            }}
          >
            <Container className="about-topic">
              <Grid container="container" spacing={2}>
                <Grid
                  item="item"
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  sx={{
                    marginLeft: "6%",
                  }}
                >
                  <Typography
                    variant="h3"
                    sx={{
                      fontFamily: "Kanit, sans-serif",
                      fontSize: "35px",
                      marginTop: "5%",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    Pioneering Tomorrow's Possibilities: Our Vision and Mission
                  </Typography>
                </Grid>
              </Grid>
            </Container>
          </motion.div>

          <motion.div
            variants={{
              hidden: {
                opacity: 0,
                y: 75,
              },
              visible: {
                opacity: 1,
                y: 0,
              },
            }}
            initial="hidden"
            animate={mainControls}
            transition={{
              duration: 0.5,
              delay: 0.25,
            }}
          >
            <Container className="about-future">
              <Grid container="container" spacing={2}>
                <Grid
                  item="item"
                  xs={12}
                  sm={12}
                  md={5}
                  lg={5}
                  xl={5}
                  sx={{
                    marginLeft: "6%",
                  }}
                >
                  <h1 className="about-vision-h1">Our Vision</h1>

                  <p className="about-vision-p">
                    Our aim is to enable students to experience an unparalleled
                    educational journey that is intellectually, socially, and
                    personally transformative, empowering with the highest
                    quality appropriate to global needs.
                  </p>
                </Grid>

                <Grid
                  item="item"
                  xs={12}
                  sm={12}
                  md={5}
                  lg={5}
                  xl={5}
                  sx={{
                    marginLeft: "6%",
                  }}
                >
                  <h1 className="about-mission-h1">Our Mission</h1>

                  <p className="about-mission-p">
                    Contributing to social equity through the pursuit of
                    education, learning research and innovation at the highest
                    international levels of excellence.
                  </p>
                </Grid>
              </Grid>
            </Container>
          </motion.div>

          <div className="about-horizontal-divider"></div>

          <Container className="about-topic">
            <Grid container="container" spacing={2}>
              <Grid
                item="item"
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                sx={{
                  marginLeft: "6%",
                }}
              >
                <Typography
                  variant="h3"
                  sx={{
                    fontFamily: "Kanit, sans-serif",
                    fontSize: "35px",
                    marginTop: "5%",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  Message from the Chancellor
                </Typography>
              </Grid>
            </Grid>
          </Container>

          <Container className="about-vc">
            <Grid container="container" spacing={2}>
              <Grid
                item="item"
                xs={12}
                sm={12}
                md={5}
                lg={5}
                xl={5}
                sx={{
                  marginLeft: "6%",
                }}
              >
                <LazyLoadImage
                  src={ITHO}
                  className="about-vc-img"
                  style={{
                    width: "100%",
                  }}
                />

                <p className="about-vc-p">Chancellor KIU</p>

                <p className="about-vc-p">Dr. Kyo Ito</p>

                <p className="about-vc-p"></p>
              </Grid>

              <Grid
                item="item"
                xs={12}
                sm={12}
                md={5}
                lg={5}
                xl={5}
                sx={{
                  marginLeft: "6%",
                }}
              >
                <p className="about-vcm-p">
                  Welcome to KIU University, where we strive to help our
                  students and faculty achieve the highest standards of academic
                  and personal excellence. We stand at the crossroads of
                  tradition and innovation, offering an education that embraces
                  Sri Lanka's unique cultural heritage while adhering to global
                  standards. Our mission is to develop world-class leaders with
                  the knowledge, skills and values ​​to prepare them to excel in
                  an increasingly interconnected world.
                </p>

                <p className="about-vcm-p">
                  We pride ourselves on our international reach and collaborate
                  with leading universities and institutions around the world.
                  These partnerships provide endless opportunities for our
                  students and faculty, fostering a rich exchange of ideas,
                  cultural perspectives, and cutting-edge research. As a
                  Japanese academic leader, I am inspired by Japan's vision of
                  discipline, resilience, and relentless pursuit of excellence.
                  We aim to instill these values ​​in our students and prepare
                  them to grow as conscientious global citizens.
                </p>

                <p className="about-vcm-p">
                  KIU University is not just an educational institution, it is a
                  community that fosters growth, innovation and leadership. We
                  strive to create an environment where all students can thrive,
                  where dreams are nurtured and the seeds of future success are
                  sown. Together, let us embark on a journey of academic
                  excellence and international engagement and spread the KIU
                  University vision to the world.
                </p>
              </Grid>
            </Grid>
          </Container>

          <div className="about-horizontal-divider"></div>

          <Container className="about-topic">
            <Grid container="container" spacing={2}>
              <Grid
                item="item"
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                sx={{
                  marginLeft: "6%",
                }}
              >
                <Typography
                  variant="h3"
                  sx={{
                    fontFamily: "Kanit, sans-serif",
                    fontSize: "35px",
                    marginTop: "5%",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  Honourable Matsumoto Hisaichi - Expert Advisor
                </Typography>
              </Grid>
            </Grid>
          </Container>

          <Container className="about-vc">
            <Grid container="container" spacing={2}>
              <Grid
                item="item"
                xs={12}
                sm={12}
                md={5}
                lg={5}
                xl={5}
                sx={{
                  marginLeft: "6%",
                }}
              >
                <LazyLoadImage
                  src={ADV}
                  className="about-vc-img"
                  style={{
                    width: "100%",
                  }}
                />

                <p className="about-vc-p">Matsumoto Hisaichi</p>

                <p className="about-vc-p">Expert Advisor</p>

                <p className="about-vc-p"></p>
              </Grid>

              <Grid
                item="item"
                xs={12}
                sm={12}
                md={5}
                lg={5}
                xl={5}
                sx={{
                  marginLeft: "6%",
                }}
              >
                <strong>
                  <p className="about-vcm-p">
                    It is with great honor that we gather today to celebrate the
                    remarkable achievements of Honourable Matsumoto Hisaichi, a
                    distinguished figure whose contributions have left an
                    incredible mark on Takamatsu City, Kagawa Prefecture, and
                    all of Japan.
                  </p>
                </strong>

                <p className="about-vcm-p">
                  From his early days, Honourable Matsumoto Hisaichi showcased a
                  unique talent in the arts. He produced marvelous works that he
                  created only once, making each piece truly one of a kind. His
                  artistic prowess quickly earned him a reputation in Takamatsu
                  City, where his works are honored and celebrated.
                </p>

                <p className="about-vcm-p">
                  Beyond his artistic talent, Honourable Matsumoto Hisaichi is
                  also a passionate golf enthusiast. His love for the game has
                  made him a well-known figure in the golfing community of
                  Takamatsu City since his youth.
                </p>
                <p className="about-vcm-p">
                  Honourable Matsumoto Hisaichi's vision for a sustainable
                  future began at a young age when he passionately introduced
                  the concept of recycling to Japan. Despite facing varying
                  public attitudes, he remained steadfast in believing that
                  recycling would elevate Japan to new heights. Today, he owns a
                  vast network of recycling companies spanning from Hokkaidō to
                  Okinawa, a testament to his enduring commitment to
                  environmental sustainability.
                </p>
                <p className="about-vcm-p">
                  His journey from humble beginnings to becoming a billionaire
                  is a story of perseverance and dedication. Honourable
                  Matsumoto Hisaichi never forgets to extend a helping hand,
                  nurturing future leaders under his guidance. His generosity
                  shines through in his substantial donations during the
                  Covid-19 outbreak, offering significant support to the
                  prefecture council.
                </p>
                <p className="about-vcm-p">
                  In addition to his numerous achievements, Honourable Matsumoto
                  Hisaichi has served as a close confidant to the former Prime
                  Minister Shinzo Abe. His solid connections extend to Tadashi
                  Yanai, the business leader known worldwide for the Uniqlo
                  brand.
                </p>
                <p className="about-vcm-p">
                  Honourable Matsumoto Hisaichi's contributions have garnered
                  recognition from the highest levels of government. He played a
                  key role in facilitating Minister Bandula Gunawardane and his
                  team's visit to Japan, which aimed to introduce Kawasaki
                  Motors to Sri Lanka. His reliability and the substantial
                  income generated by his companies have earned him high regard
                  from the Japanese government.
                </p>
                <p className="about-vcm-p">
                  Today, KIU is proud to bestow upon him the title of Expert
                  Advisor at KIU, recognizing his remarkable achievements and
                  contributions to the world.
                </p>
              </Grid>
            </Grid>
          </Container>

          <div className="about-horizontal-divider"></div>

          <Container className="about-topic">
            <Grid container="container" spacing={2}>
              <Grid
                item="item"
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                sx={{
                  marginLeft: "6%",
                }}
              >
                <Typography
                  variant="h3"
                  sx={{
                    fontFamily: "Kanit, sans-serif",
                    fontSize: "35px",
                    marginTop: "5%",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  Message from the President
                </Typography>
              </Grid>
            </Grid>
          </Container>

          <Container className="about-vc">
            <Grid container="container" spacing={2}>
              <Grid
                item="item"
                xs={12}
                sm={12}
                md={5}
                lg={5}
                xl={5}
                sx={{
                  marginLeft: "6%",
                }}
              >
                <LazyLoadImage
                  src={SIR}
                  className="about-vc-img"
                  style={{
                    width: "100%",
                  }}
                />

                <p className="about-vc-p">KIU Chairman</p>

                <p className="about-vc-p">Dr. Jagath Seneviratne</p>

                <p className="about-vc-p"></p>
              </Grid>

              <Grid
                item="item"
                xs={12}
                sm={12}
                md={5}
                lg={5}
                xl={5}
                sx={{
                  marginLeft: "6%",
                }}
              >
                <strong>
                  <p className="about-vcm-p">Your future is global</p>
                </strong>

                <p className="about-vcm-p">
                  Success today requires a global perspective and the skills to
                  work with people from different cultures and diverse
                  traditions. At KIU, you will have the cultural exposure to
                  emerge as a competent and responsible professional who meets
                  both local and global needs.
                </p>

                <p className="about-vcm-p">
                  Our high standards and innovative learning approaches ensure
                  that students are better equipped to address and solve complex
                  problems. Our teaching sites, laboratories, skill training
                  labs and IT facilities are constantly being upgraded to meet
                  the needs of the country. We have responded to the country's
                  demand for more graduates by expanding our institutions to
                  accommodate the growing number of students.
                </p>
                <p className="about-vcm-p">
                  A university education is the starting point of your life. Of
                  course, it's about obtaining professional qualifications and a
                  highly respected degree. But it's so much more. The in-depth
                  knowledge you gain from KIU will help you grow as a person,
                  discover new perspectives and expose you to new ways of
                  learning.
                </p>
                <p className="about-vcm-p">
                  Choosing a university is one of the biggest decisions you will
                  ever make. Whatever your choice, we wish you success in your
                  studies.
                </p>
              </Grid>
            </Grid>
          </Container>

          <div className="about-horizontal-divider"></div>

          <Container className="about-topic">
            <Grid container="container" spacing={2}>
              <Grid
                item="item"
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                sx={{
                  marginLeft: "6%",
                }}
              >
                <Typography
                  variant="h3"
                  sx={{
                    fontFamily: "Kanit, sans-serif",
                    fontSize: "35px",
                    marginTop: "5%",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  Message from the Vice Chancellor
                </Typography>
              </Grid>
            </Grid>
          </Container>

          <Container className="about-vc">
            <Grid container="container" spacing={2}>
              <Grid
                item="item"
                xs={12}
                sm={12}
                md={5}
                lg={5}
                xl={5}
                sx={{
                  marginLeft: "6%",
                }}
              >
                <LazyLoadImage
                  src={VCIMG}
                  className="about-vc-img"
                  style={{
                    width: "100%",
                  }}
                />

                <p className="about-vc-p">Vice Chancellor</p>

                <p className="about-vc-p">Dr. Sajeewanie Liyana-arachchi</p>

                <p className="about-vc-p"></p>
              </Grid>

              <Grid
                item="item"
                xs={12}
                sm={12}
                md={5}
                lg={5}
                xl={5}
                sx={{
                  marginLeft: "6%",
                }}
              >
                <p className="about-vcm-p">
                  Welcome to KIU University, a distinguished institution
                  dedicated to fostering academic excellence and innovation. As
                  a university approved by the Ministry of Higher Education,
                  recognized by the University Grants Commission, and proudly
                  listed among Commonwealth universities, we are committed to
                  providing a world-class education to our students.
                </p>

                <p className="about-vcm-p">
                  At KIU, we strive to bridge the gap between academia and
                  industry, ensuring that our students are well-prepared to meet
                  the demands of the global workforce. Our curriculum is
                  meticulously designed to equip students with the skills,
                  knowledge, and attitudes necessary to thrive in their chosen
                  fields.
                </p>

                <p className="about-vcm-p">
                  Our graduates receive a robust and industry-relevant education
                  at KIU, making them highly sought after by employers
                  worldwide. KIU University is well-equipped with cutting-edge
                  laboratories, advanced research centers, modern classrooms,
                  and extensive library resources to support our students'
                  academic and personal growth. Research and innovation are
                  central to our mission, and we continuously expand our
                  research efforts. This creates an environment where new ideas
                  and discoveries can flourish. Our academic programs are
                  recognized for their quality and relevance by professional
                  bodies and industries.
                </p>

                <p className="about-vcm-p">
                  We take pride in creating a vibrant and inclusive campus
                  community where students can participate in a variety of
                  extracurricular activities, including sports, arts,
                  leadership, and volunteer opportunities. This holistic
                  approach ensures that our students excel academically while
                  also becoming socially responsible and culturally aware.
                </p>
                <p className="about-vcm-p">
                  As we look towards the future, KIU remains committed to its
                  vision of delivering high-quality education and being at the
                  forefront of academic excellence. We welcome you to discover
                  the opportunities available at KIU University and join us in
                  our quest for knowledge, innovation, and global impact.
                </p>
              </Grid>
            </Grid>
          </Container>
        </div>

        <div className="about-horizontal-divider"></div>

        <Container className="about-topic">
          <Grid container="container" spacing={2}>
            <Grid
              item="item"
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{
                marginLeft: "6%",
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  fontFamily: "Kanit, sans-serif",
                  fontSize: "35px",
                  marginTop: "5%",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                Student Voices: Buzz About Our Campus
              </Typography>
            </Grid>
          </Grid>
        </Container>

        <Container className="about-st">
          <Grid container="container" spacing={2}>
            {videos.map((item, index) => (
              <Grid
                key={index}
                item="item"
                xs={12}
                sm={12}
                md={5}
                lg={5}
                xl={5}
                sx={{
                  marginLeft: "6%",
                }}
              >
                <Box
                  width="100%"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    py: 1,
                    px: 1,
                  }}
                >
                  <ReactPlayer
                    url={item.testamonial}
                    controls={true}
                    playing={index === 0}
                    // Autoplay only for the first video
                    width="100%"
                    height="35vh"
                    light={item.thumbnail}
                    config={{
                      file: {
                        attributes: {
                          controlsList: "nodownload", // Disable download button
                        },
                      },
                    }}
                  />
                </Box>
              </Grid>
            ))}
          </Grid>
        </Container>

        <div className="footer-div">
          <Footer />
        </div>
      </div>
    </>
  );
};

export default About;
