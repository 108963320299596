import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CardHeader, Container, Grid, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useInView } from "react-intersection-observer";
import postgrad from "../assets/postgrad.jpg";
import shortC from "../assets/shortC.jpg";
import ug from "../assets/ug.png";
import Head from "../components/Head";
import Header from "../components/Header";
import Popup from "../components/Popup";
import Footer from "../components/footer";
import Mba from "../components/postgraduate/Mba";
import Mnd from "../components/postgraduate/Mnd";
import Mnm from "../components/postgraduate/Mnm";
import Mpn from "../components/postgraduate/Mpn";
import Msn from "../components/postgraduate/Msn";
import CC from "../components/shortcource/CC";
import CCA from "../components/shortcource/CCA";
import CDM from "../components/shortcource/CDM";
import CELA from "../components/shortcource/CELA";
import CELE from "../components/shortcource/CELE";
import CELI from "../components/shortcource/CELI";
import CFMA from "../components/shortcource/CFMA";
import CHRA from "../components/shortcource/CHRA";
import CIP from "../components/shortcource/CIP";
import CLA from "../components/shortcource/CLA";
import DALM from "../components/shortcource/DALM";
import DFW from "../components/shortcource/DFW";
import DIA from "../components/shortcource/DIA";
import JLP from "../components/shortcource/JLP";
import AC from "../components/undergraduate/AC";
import BA from "../components/undergraduate/BA";
import BS from "../components/undergraduate/BS";
import CN from "../components/undergraduate/CN";
import DS from "../components/undergraduate/DS";
import HR from "../components/undergraduate/HR";
import LLB from "../components/undergraduate/LLB";
import MIS from "../components/undergraduate/MIS";
import MK from "../components/undergraduate/MK";
import MSA from "../components/undergraduate/MSA";
import NUR from "../components/undergraduate/NUR";
import PSY from "../components/undergraduate/PSY";
import SE from "../components/undergraduate/SE";
import "./WeOffer.css";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  // marginLeft: 'auto',
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const WeOffer = () => {
  const [expanded1, setExpanded1] = useState(false);
  const [expanded2, setExpanded2] = useState(false);
  const [expanded3, setExpanded3] = useState(false);
  const [buttonPopup1, setButtonPopup1] = useState(false);
  const [buttonPopup2, setButtonPopup2] = useState(false);
  const [buttonPopup3, setButtonPopup3] = useState(false);
  const [buttonPopup4, setButtonPopup4] = useState(false);
  const [buttonPopup5, setButtonPopup5] = useState(false);
  const [buttonPopup6, setButtonPopup6] = useState(false);
  const [buttonPopup7, setButtonPopup7] = useState(false);
  const [buttonPopup8, setButtonPopup8] = useState(false);
  const [buttonPopup9, setButtonPopup9] = useState(false);
  const [buttonPopup10, setButtonPopup10] = useState(false);
  const [buttonPopup11, setButtonPopup11] = useState(false);
  const [buttonPopup12, setButtonPopup12] = useState(false);
  const [buttonPopup13, setButtonPopup13] = useState(false);
  const [buttonPopup14, setButtonPopup14] = useState(false);
  const [buttonPopup15, setButtonPopup15] = useState(false);
  const [buttonPopup16, setButtonPopup16] = useState(false);
  const [buttonPopup17, setButtonPopup17] = useState(false);
  const [buttonPopup18, setButtonPopup18] = useState(false);
  const [buttonPopup19, setButtonPopup19] = useState(false);
  const [buttonPopup20, setButtonPopup20] = useState(false);
  const [buttonPopup21, setButtonPopup21] = useState(false);
  const [buttonPopup22, setButtonPopup22] = useState(false);
  const [buttonPopup23, setButtonPopup23] = useState(false);
  const [buttonPopup24, setButtonPopup24] = useState(false);
  const [buttonPopup25, setButtonPopup25] = useState(false);
  const [buttonPopup26, setButtonPopup26] = useState(false);
  const [buttonPopup27, setButtonPopup27] = useState(false);
  const [buttonPopup28, setButtonPopup28] = useState(false);
  const [buttonPopup29, setButtonPopup29] = useState(false);
  const [buttonPopup30, setButtonPopup30] = useState(false);
  const [buttonPopup31, setButtonPopup31] = useState(false);
  const [buttonPopup32, setButtonPopup32] = useState(false);
  const [buttonPopup33, setButtonPopup33] = useState(false);
  const [buttonPopup34, setButtonPopup34] = useState(false);

  const handleExpandClick1 = () => {
    setExpanded1(!expanded1);
  };
  const handleExpandClick2 = () => {
    setExpanded2(!expanded2);
  };
  const handleExpandClick3 = () => {
    setExpanded3(!expanded3);
  };
  // const buttonStyles = {     backgroundColor: 'transparent',     border: '2px
  // solid #00AAFD',  Blue outline     color: '#00AAFD',  Blue text color   };

  const [ref1, inView1] = useInView({ threshold: 0.2 });

  useEffect(() => {
    if (inView1) {
    }
  }, [inView1]);

  const [ref2, inView2] = useInView({ threshold: 0.2 });

  useEffect(() => {
    if (inView2) {
    }
  }, [inView2]);

  const [ref3, inView3] = useInView({ threshold: 0.2 });

  useEffect(() => {
    if (inView3) {
    }
  }, [inView3]);

  const [ref4, inView4] = useInView({ threshold: 0.2 });

  useEffect(() => {
    if (inView4) {
    }
  }, [inView4]);

  const [ref5, inView5] = useInView({ threshold: 0.2 });

  useEffect(() => {
    if (inView5) {
    }
  }, [inView5]);

  const [ref6, inView6] = useInView({ threshold: 0.2 });

  useEffect(() => {
    if (inView6) {
    }
  }, [inView6]);

  const [ref7, inView7] = useInView({ threshold: 0.2 });

  useEffect(() => {
    if (inView7) {
    }
  }, [inView7]);

  const [ref8, inView8] = useInView({ threshold: 0.2 });

  useEffect(() => {
    if (inView8) {
    }
  }, [inView8]);

  const [ref9, inView9] = useInView({ threshold: 0.2 });

  useEffect(() => {
    if (inView9) {
    }
  }, [inView9]);

  return (
    <div className="offer">
      <Header />

      <div className="head-div">
        <Head />
      </div>

      <motion.div
        ref={ref1}
        initial={{
          opacity: 0,
          y: 20,
        }}
        animate={
          inView1
            ? {
                opacity: 1,
                y: 0,
              }
            : {
                opacity: 0,
                y: 20,
              }
        }
        transition={{
          duration: 0.5,
        }}
      >
        <Container>
          <Grid container="container" spacing={2}>
            <Grid
              item="item"
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{
                marginLeft: "6%",
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  fontFamily: "Kanit, sans-serif",
                  fontSize: "35px",
                  marginTop: "5%",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                Diverse Learning Pathways: Navigating the Spectrum of University
                Courses
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </motion.div>

      <motion.div
        ref={ref2}
        initial={{
          opacity: 0,
          y: 20,
        }}
        animate={
          inView2
            ? {
                opacity: 1,
                y: 0,
              }
            : {
                opacity: 0,
                y: 20,
              }
        }
        transition={{
          duration: 0.5,
        }}
      >
        <Container>
          <Grid container="container" spacing={2}>
            <Grid
              item="item"
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{
                marginLeft: "6%",
              }}
            >
              <Typography className="offer-des">
                "At KIU, we offer a diverse array of undergraduate programs,
                postgraduate opportunities, and short courses. Whether you have
                local or international aspirations, our courses meet recognized
                standards, ensuring you have the freedom to choose the path that
                suits your goals. Your journey to success begins here. Explore
                the possibilities with us!"
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </motion.div>

      <motion.div
        ref={ref3}
        initial={{
          opacity: 0,
          y: 20,
        }}
        animate={
          inView3
            ? {
                opacity: 1,
                y: 0,
              }
            : {
                opacity: 0,
                y: 20,
              }
        }
        transition={{
          duration: 0.5,
        }}
      >
        <div className="offer-horizontal-divider"></div>
      </motion.div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={1.5} lg={1.5} xl={1.5}></Grid>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={8}
            lg={8}
            xl={8}
            sx={{
              marginLeft: "6%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Card>
              <CardHeader
                title={
                  <Typography
                    sx={{
                      fontFamily: "Kanit, sans-serif",
                      fontSize: "35px",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    Undergraduate Programs
                  </Typography>
                }
              />
              <CardMedia
                component="img"
                width="100%"
                height="100%"
                image={ug}
                alt="KIU UNI"
                sx={{ padding: "20px" }}
              />
              <CardContent>
                <Typography className="offer-des-ug">
                  Undergraduation refers to the initial phase of higher
                  education that follows a student's completion of secondary
                  education. It typically encompasses a period of study lasting
                  three to four years, during which students pursue a bachelor's
                  degree in a specific field or discipline. Undergraduate
                  programs are designed to provide students with a foundational
                  education, introducing them to a broad range of subjects while
                  allowing them to specialize in their chosen major. This stage
                  of education is characterized by a diverse range of academic
                  and extracurricular experiences, helping students develop
                  critical thinking, problem-solving skills, and a deeper
                  understanding of their chosen field. It serves as a stepping
                  stone for further academic pursuits or entry into the
                  workforce upon graduation.
                </Typography>
              </CardContent>
              <CardActions
                disableSpacing="disableSpacing"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontFamily: "Libre Baskerville, serif",
                    color: "red",
                    fontSize: "25px",
                    cursor: "pointer",
                  }}
                  onClick={handleExpandClick1}
                >
                  VIEW ALL PROGRAMS
                </Typography>
                <ExpandMore
                  size="5rem"
                  expand={expanded1}
                  onClick={handleExpandClick1}
                  aria-expanded={expanded1}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </ExpandMore>
              </CardActions>
              <Collapse
                in={expanded1}
                timeout="auto"
                unmountOnExit="unmountOnExit"
              >
                <CardContent>
                  <Typography
                    paragraph="paragraph"
                    className="ug-crs"
                    onClick={() => setButtonPopup1(true)}
                  >
                    Bachelor of Management Honours in Business Analytics
                  </Typography>
                  <Popup trigger={buttonPopup1} setTrigger={setButtonPopup1}>
                    <BA onClose={() => setButtonPopup1(false)} />
                  </Popup>

                  {/* <Typography
                    paragraph="paragraph"
                    className="ug-crs"
                    onClick={() => setButtonPopup2(true)}
                  >
                    Bachelor of Science Honours in Artificial Intelligence
                  </Typography> */}
                  {/* <Popup trigger={buttonPopup2} setTrigger={setButtonPopup2}>
                                            <AI onClose={() => setButtonPopup2(false)}/>
                                        </Popup> */}

                  <Typography
                    paragraph="paragraph"
                    className="ug-crs"
                    onClick={() => setButtonPopup3(true)}
                  >
                    Bachelor of Science Honours in Data Science
                  </Typography>
                  <Popup trigger={buttonPopup3} setTrigger={setButtonPopup3}>
                    <DS onClose={() => setButtonPopup3(false)} />
                  </Popup>

                  <Typography
                    paragraph="paragraph"
                    className="ug-crs"
                    onClick={() => setButtonPopup4(true)}
                  >
                    Bachelor of Science Honours in Software Engineering
                  </Typography>
                  <Popup trigger={buttonPopup4} setTrigger={setButtonPopup4}>
                    <SE onClose={() => setButtonPopup4(false)} />
                  </Popup>

                  <Typography
                    paragraph="paragraph"
                    className="ug-crs"
                    onClick={() => setButtonPopup5(true)}
                  >
                    Bachelor of Science Honours in Computer Networks and Cyber
                    Security
                  </Typography>
                  <Popup trigger={buttonPopup5} setTrigger={setButtonPopup5}>
                    <CN onClose={() => setButtonPopup5(false)} />
                  </Popup>

                  <Typography
                    paragraph="paragraph"
                    className="ug-crs"
                    onClick={() => setButtonPopup6(true)}
                  >
                    Bachelor of Science Honours in Management Information System
                  </Typography>
                  <Popup trigger={buttonPopup6} setTrigger={setButtonPopup6}>
                    <MIS onClose={() => setButtonPopup6(false)} />
                  </Popup>

                  <Typography
                    paragraph="paragraph"
                    className="ug-crs"
                    onClick={() => setButtonPopup7(true)}
                  >
                    Bachelor of Management Honours in Accounting
                  </Typography>
                  <Popup trigger={buttonPopup7} setTrigger={setButtonPopup7}>
                    <AC onClose={() => setButtonPopup7(false)} />
                  </Popup>

                  <Typography
                    paragraph="paragraph"
                    className="ug-crs"
                    onClick={() => setButtonPopup8(true)}
                  >
                    Bachelor of Management Honours in Human Resource
                  </Typography>
                  <Popup trigger={buttonPopup8} setTrigger={setButtonPopup8}>
                    <HR onClose={() => setButtonPopup8(false)} />
                  </Popup>

                  <Typography
                    paragraph="paragraph"
                    className="ug-crs"
                    onClick={() => setButtonPopup9(true)}
                  >
                    Bachelor of Management Honours in Marketing
                  </Typography>
                  <Popup trigger={buttonPopup9} setTrigger={setButtonPopup9}>
                    <MK onClose={() => setButtonPopup9(false)} />
                  </Popup>

                  <Typography
                    paragraph="paragraph"
                    className="ug-crs"
                    onClick={() => setButtonPopup10(true)}
                  >
                    Bachelor of Science Honours in Psychology
                  </Typography>
                  <Popup trigger={buttonPopup10} setTrigger={setButtonPopup10}>
                    <PSY onClose={() => setButtonPopup10(false)} />
                  </Popup>

                  <Typography
                    paragraph="paragraph"
                    className="ug-crs"
                    onClick={() => setButtonPopup11(true)}
                  >
                    Bachelor of Science Honours in Biomedical Science
                  </Typography>
                  <Popup trigger={buttonPopup11} setTrigger={setButtonPopup11}>
                    <BS onClose={() => setButtonPopup11(false)} />
                  </Popup>

                  <Typography
                    paragraph="paragraph"
                    className="ug-crs"
                    onClick={() => setButtonPopup12(true)}
                  >
                    Bachelor of Science Honours in Medical Science in
                    Acupuncture
                  </Typography>
                  <Popup trigger={buttonPopup12} setTrigger={setButtonPopup12}>
                    <MSA onClose={() => setButtonPopup12(false)} />
                  </Popup>

                  {/* <Typography
                                            paragraph="paragraph"
                                            className='ug-crs'
                                            onClick={() => setButtonPopup13(true)}>
                                            Bachelor of Science Honours Medical Science in KAATSU
                                        </Typography>
                                        <Popup trigger={buttonPopup13} setTrigger={setButtonPopup13}>
                                            <MSK onClose={() => setButtonPopup13(false)}/>
                                        </Popup> */}

                  <Typography
                    paragraph="paragraph"
                    className="ug-crs"
                    onClick={() => setButtonPopup14(true)}
                  >
                    Bachelor of Science Honours in Nursing (FOR REGISTERED
                    NURSES)
                  </Typography>
                  <Popup trigger={buttonPopup14} setTrigger={setButtonPopup14}>
                    <NUR onClose={() => setButtonPopup14(false)} />
                  </Popup>

                  <Typography
                    paragraph="paragraph"
                    className="ug-crs"
                    onClick={() => setButtonPopup28(true)}
                  >
                    Bachelor Of Laws Honours
                  </Typography>
                  <Popup trigger={buttonPopup28} setTrigger={setButtonPopup28}>
                    <LLB onClose={() => setButtonPopup28(false)} />
                  </Popup>
                </CardContent>
              </Collapse>
            </Card>
          </Grid>
          <Grid item="item" xs={12} sm={12} md={1.5} lg={1.5} xl={1.5}></Grid>
        </Grid>
      </Container>
      {/* </motion.div> */}

      <motion.div
        ref={ref6}
        initial={{
          opacity: 0,
          y: 20,
        }}
        animate={
          inView6
            ? {
                opacity: 1,
                y: 0,
              }
            : {
                opacity: 0,
                y: 20,
              }
        }
        transition={{
          duration: 0.5,
        }}
      >
        <div className="offer-horizontal-divider"></div>
      </motion.div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={1.5} lg={1.5} xl={1.5}></Grid>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={8}
            lg={8}
            xl={8}
            sx={{
              marginLeft: "6%",
            }}
          >
            <Card>
              <CardHeader
                title={
                  <Typography
                    sx={{
                      fontFamily: "Kanit, sans-serif",
                      fontSize: "35px",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    Postgraduate Programs
                  </Typography>
                }
              />
              <CardMedia
                component="img"
                width="100%"
                height="100%"
                image={postgrad}
                alt="KIU UNI"
                sx={{ padding: "20px" }}
              />
              <CardContent>
                <Typography className="offer-des-ug">
                  At KIU, postgraduate education transcends boundaries,
                  unlocking a world of specialized knowledge, cutting-edge
                  research, and transformative experiences. Our postgraduate
                  programmes, encompassing Master’s and MPhil, are meticulously
                  designed to elevate your academic and professional journey.
                  <br />
                  Accessibility and flexibility of our postgraduate programmes
                  set KIU apart. The convenience of online learning and
                  part-time structures empower postgraduate students to
                  seamlessly integrate their education with existing
                  commitments. <br />A postgraduate degree from KIU is a key to
                  new career prospects and elevates earning potential. Explore
                  the world of postgraduate education at KIU University – a
                  place where knowledge knows no bounds, and success becomes a
                  tangible reality.
                </Typography>
              </CardContent>
              <CardActions
                disableSpacing="disableSpacing"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontFamily: "Libre Baskerville, serif",
                    color: "red",
                    fontSize: "25px",
                    cursor: "pointer",
                  }}
                  onClick={handleExpandClick2}
                >
                  VIEW ALL PROGRAMS
                </Typography>
                <ExpandMore
                  size="5rem"
                  expand={expanded2}
                  onClick={handleExpandClick2}
                  aria-expanded={expanded2}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </ExpandMore>
              </CardActions>
              <Collapse
                in={expanded2}
                timeout="auto"
                unmountOnExit="unmountOnExit"
              >
                <CardContent>
                  <Typography
                    paragraph="paragraph"
                    className="ug-crs"
                    onClick={() => setButtonPopup15(true)}
                  >
                    Master Of Business Administration (SLQF 10)
                  </Typography>
                  <Popup trigger={buttonPopup15} setTrigger={setButtonPopup15}>
                    <Mba onClose={() => setButtonPopup15(false)} />
                  </Popup>

                  <Typography
                    paragraph="paragraph"
                    className="ug-crs"
                    onClick={() => setButtonPopup16(true)}
                  >
                    Master of Nursing Education (SLQF 9)
                  </Typography>
                  <Popup trigger={buttonPopup16} setTrigger={setButtonPopup16}>
                    <Mnd onClose={() => setButtonPopup16(false)} />
                  </Popup>

                  <Typography
                    paragraph="paragraph"
                    className="ug-crs"
                    onClick={() => setButtonPopup17(true)}
                  >
                    Master of Nursing Management (SLQF 9)
                  </Typography>
                  <Popup trigger={buttonPopup17} setTrigger={setButtonPopup17}>
                    <Mnm onClose={() => setButtonPopup17(false)} />
                  </Popup>

                  <Typography
                    paragraph="paragraph"
                    className="ug-crs"
                    onClick={() => setButtonPopup18(true)}
                  >
                    Master of Science in Nursing (SLQF 10)
                  </Typography>
                  <Popup trigger={buttonPopup18} setTrigger={setButtonPopup18}>
                    <Msn onClose={() => setButtonPopup18(false)} />
                  </Popup>

                  <Typography
                    paragraph="paragraph"
                    className="ug-crs"
                    onClick={() => setButtonPopup27(true)}
                  >
                    Master Of Philosophy In Nursing (SLQF 11)
                  </Typography>
                  <Popup trigger={buttonPopup27} setTrigger={setButtonPopup27}>
                    <Mpn onClose={() => setButtonPopup27(false)} />
                  </Popup>
                </CardContent>
              </Collapse>
            </Card>
          </Grid>
          <Grid item="item" xs={12} sm={12} md={1.5} lg={1.5} xl={1.5}></Grid>
        </Grid>
      </Container>
      {/* </motion.div> */}

      <motion.div
        ref={ref8}
        initial={{
          opacity: 0,
          y: 20,
        }}
        animate={
          inView8
            ? {
                opacity: 1,
                y: 0,
              }
            : {
                opacity: 0,
                y: 20,
              }
        }
        transition={{
          duration: 0.5,
        }}
      >
        <div className="offer-horizontal-divider"></div>
      </motion.div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={1.5} lg={1.5} xl={1.5}></Grid>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={8}
            lg={8}
            xl={8}
            sx={{
              marginLeft: "6%",
            }}
          >
            <Card>
              <CardHeader
                title={
                  <Typography
                    sx={{
                      fontFamily: "Kanit, sans-serif",
                      fontSize: "35px",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    Short Courses
                  </Typography>
                }
              />
              <CardMedia
                component="img"
                width="100%"
                height="100%"
                image={shortC}
                alt="KIU UNI"
                sx={{ padding: "20px" }}
              />
              <CardContent>
                <Typography className="offer-des-ug">
                  Discover a world of opportunities with KIU’s dynamic short
                  courses designed for the modern learner. Our carefully crafted
                  programs offer the flexibility to acquire in-demand skills,
                  explore diverse interests, and enhance your knowledge.
                  <br />
                  At KIU, we prioritize not just education, but a supportive
                  learning environment. Benefit from expert instructors who
                  bring real-world insights and valuable networking
                  opportunities that extend beyond the course duration. Whether
                  you’re seeking career advancement, personal growth, or
                  satisfying your curiosity, KIU’s short courses are a modern
                  and convenient pathway to staying relevant and continuously
                  developing in your chosen field.
                  <br />
                  Embark on a journey of knowledge and skill acquisition with
                  KIU’s short courses – where learning meets flexibility.
                </Typography>
              </CardContent>
              <CardActions
                disableSpacing="disableSpacing"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontFamily: "Libre Baskerville, serif",
                    color: "red",
                    fontSize: "25px",
                    cursor: "pointer",
                  }}
                  onClick={handleExpandClick3}
                >
                  VIEW ALL PROGRAMS
                </Typography>
                <ExpandMore
                  size="5rem"
                  expand={expanded3}
                  onClick={handleExpandClick3}
                  aria-expanded={expanded3}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </ExpandMore>
              </CardActions>
              <Collapse
                in={expanded3}
                timeout="auto"
                unmountOnExit="unmountOnExit"
              >
                <CardContent>
                  <Typography
                    paragraph="paragraph"
                    className="ug-crs"
                    onClick={() => setButtonPopup19(true)}
                  >
                    Certificate in IELTS Preparation
                  </Typography>
                  <Popup trigger={buttonPopup19} setTrigger={setButtonPopup19}>
                    <CIP onClose={() => setButtonPopup19(false)} />
                  </Popup>

                  <Typography
                    paragraph="paragraph"
                    className="ug-crs"
                    onClick={() => setButtonPopup20(true)}
                  >
                    Certificate Course for Laboratory Assistant
                  </Typography>
                  <Popup trigger={buttonPopup20} setTrigger={setButtonPopup20}>
                    <CLA onClose={() => setButtonPopup20(false)} />
                  </Popup>

                  <Typography
                    paragraph="paragraph"
                    className="ug-crs"
                    onClick={() => setButtonPopup21(true)}
                  >
                    Certificate Course in Human Resource Analytics
                  </Typography>
                  <Popup trigger={buttonPopup21} setTrigger={setButtonPopup21}>
                    <CHRA onClose={() => setButtonPopup21(false)} />
                  </Popup>

                  <Typography
                    paragraph="paragraph"
                    className="ug-crs"
                    onClick={() => setButtonPopup22(true)}
                  >
                    Certificate Course in Financial and Management Accounting
                  </Typography>
                  <Popup trigger={buttonPopup22} setTrigger={setButtonPopup22}>
                    <CFMA onClose={() => setButtonPopup22(false)} />
                  </Popup>

                  <Typography
                    paragraph="paragraph"
                    className="ug-crs"
                    onClick={() => setButtonPopup23(true)}
                  >
                    Certificate Course In Digital Marketing
                  </Typography>
                  <Popup trigger={buttonPopup23} setTrigger={setButtonPopup23}>
                    <CDM onClose={() => setButtonPopup23(false)} />
                  </Popup>

                  <Typography
                    paragraph="paragraph"
                    className="ug-crs"
                    onClick={() => setButtonPopup24(true)}
                  >
                    Certificate Course In Caregiver
                  </Typography>
                  <Popup trigger={buttonPopup24} setTrigger={setButtonPopup24}>
                    <CC onClose={() => setButtonPopup24(false)} />
                  </Popup>

                  <Typography
                    paragraph="paragraph"
                    className="ug-crs"
                    onClick={() => setButtonPopup25(true)}
                  >
                    Certificate in Clinical Competency in Acupuncture
                  </Typography>
                  <Popup trigger={buttonPopup25} setTrigger={setButtonPopup25}>
                    <CCA onClose={() => setButtonPopup25(false)} />
                  </Popup>

                  <Typography
                    paragraph="paragraph"
                    className="ug-crs"
                    onClick={() => setButtonPopup26(true)}
                  >
                    Diploma In Fabrication And Welding
                  </Typography>
                  <Popup trigger={buttonPopup26} setTrigger={setButtonPopup26}>
                    <DFW onClose={() => setButtonPopup26(false)} />
                  </Popup>

                  <Typography
                    paragraph="paragraph"
                    className="ug-crs"
                    onClick={() => setButtonPopup29(true)}
                  >
                    Diploma In Acupuncture
                  </Typography>
                  <Popup trigger={buttonPopup29} setTrigger={setButtonPopup29}>
                    <DIA onClose={() => setButtonPopup29(false)} />
                  </Popup>

                  <Typography
                    paragraph="paragraph"
                    className="ug-crs"
                    onClick={() => setButtonPopup30(true)}
                  >
                    Japanese Language Programs
                  </Typography>
                  <Popup trigger={buttonPopup30} setTrigger={setButtonPopup30}>
                    <JLP onClose={() => setButtonPopup30(false)} />
                  </Popup>

                  <Typography
                    paragraph="paragraph"
                    className="ug-crs"
                    onClick={() => setButtonPopup31(true)}
                  >
                    Certificate Course in English Language (Elementary Level)
                  </Typography>
                  <Popup trigger={buttonPopup31} setTrigger={setButtonPopup31}>
                    <CELE onClose={() => setButtonPopup31(false)} />
                  </Popup>

                  <Typography
                    paragraph="paragraph"
                    className="ug-crs"
                    onClick={() => setButtonPopup32(true)}
                  >
                    Certificate Course in English Language (Intermediate Level)
                  </Typography>
                  <Popup trigger={buttonPopup32} setTrigger={setButtonPopup32}>
                    <CELI onClose={() => setButtonPopup32(false)} />
                  </Popup>

                  <Typography
                    paragraph="paragraph"
                    className="ug-crs"
                    onClick={() => setButtonPopup33(true)}
                  >
                    Certificate Course in English Language (Advanced Level)
                  </Typography>
                  <Popup trigger={buttonPopup33} setTrigger={setButtonPopup33}>
                    <CELA onClose={() => setButtonPopup33(false)} />
                  </Popup>

                  <Typography
                    paragraph="paragraph"
                    className="ug-crs"
                    onClick={() => setButtonPopup34(true)}
                  >
                    Diploma in Agriculture and Livestock Management(DALM)
                  </Typography>
                  <Popup trigger={buttonPopup34} setTrigger={setButtonPopup34}>
                    <DALM onClose={() => setButtonPopup34(false)} />
                  </Popup>
                </CardContent>
              </Collapse>
            </Card>
          </Grid>
          <Grid item="item" xs={12} sm={12} md={1.5} lg={1.5} xl={1.5}></Grid>
        </Grid>
      </Container>
      {/* </motion.div> */}

      <div className="footer-div">
        <Footer />
      </div>
    </div>
  );
};

export default WeOffer;
