import { Box, Container, Grid, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { Button, Drawer } from "antd";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { MdOutlineCloseFullscreen } from "react-icons/md";
import { useInView } from "react-intersection-observer";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useNavigate } from "react-router-dom";
import behavio from "../assets/behavio.jpg";
import computer from "../assets/computer.jpg";
import education from "../assets/education.jpg";
import graduate from "../assets/graduate.jpg";
import health from "../assets/health.jpg";
import law from "../assets/law.jpg";
import livestock from "../assets/livestock.jpg";
import management from "../assets/management.jpg";
import nursing from "../assets/nursing.jpeg";
import Head from "../components/Head";
import Header from "../components/Header";
import FOBS from "../components/faculties/FOBS";
import FOCSE from "../components/faculties/FOCSE";
import FOEL from "../components/faculties/FOEL";
import FOGS from "../components/faculties/FOGS";
import FOHS from "../components/faculties/FOHS";
import FOL from "../components/faculties/FOL";
import FOM from "../components/faculties/FOM";
import FON from "../components/faculties/FON";
import KSLM from "../components/faculties/KSLM";
import Footer from "../components/footer";
import "./Faculties.css";

const Faculties = () => {
  const [open1, setOpen1] = useState(false);
  const showDrawer1 = () => {
    setOpen1(true);
  };

  const [open2, setOpen2] = useState(false);
  const showDrawer2 = () => {
    setOpen2(true);
  };

  const [open3, setOpen3] = useState(false);
  const showDrawer3 = () => {
    setOpen3(true);
  };

  const [open4, setOpen4] = useState(false);
  const showDrawer4 = () => {
    setOpen4(true);
  };

  const [open5, setOpen5] = useState(false);
  const showDrawer5 = () => {
    setOpen5(true);
  };

  const [open6, setOpen6] = useState(false);
  const showDrawer6 = () => {
    setOpen6(true);
  };

  const [open7, setOpen7] = useState(false);
  const showDrawer7 = () => {
    setOpen7(true);
  };

  const [open8, setOpen8] = useState(false);
  const showDrawer8 = () => {
    setOpen8(true);
  };

  const [open9, setOpen9] = useState(false);
  const showDrawer9 = () => {
    setOpen9(true);
  };

  const [ref1, inView1] = useInView({ threshold: 0.2 });

  useEffect(() => {
    if (inView1) {
    }
  }, [inView1]);

  const [ref2, inView2] = useInView({ threshold: 0.2 });

  useEffect(() => {
    if (inView2) {
    }
  }, [inView2]);

  const [ref3, inView3] = useInView({ threshold: 0.2 });

  useEffect(() => {
    if (inView3) {
    }
  }, [inView3]);

  const [ref4, inView4] = useInView({ threshold: 0.2 });

  useEffect(() => {
    if (inView4) {
    }
  }, [inView4]);

  const [ref5, inView5] = useInView({ threshold: 0.2 });
  const [ref6, inView6] = useInView({ threshold: 0.2 });

  useEffect(() => {
    if (inView5) {
    }
  }, [inView5]);

  useEffect(() => {
    if (inView6) {
    }
  }, [inView6]);

  const buttonStyles1 = {
    marginTop: "2em",
    backgroundColor: "transparent",
    border: "2px solid #002FFF", // Blue outline
    color: "#002FFF", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const navigate = useNavigate();
  const handleBtnClick = (path) => {
    navigate(path);
  };

  const drawerWidth = window.innerWidth < 600 ? "70%" : "50%";

  return (
    <div className="faculty">
      <Header />

      <div className="head-div">
        <Head />
      </div>

      <motion.div
        ref={ref1}
        initial={{
          opacity: 0,
          y: 20,
        }}
        animate={
          inView1
            ? {
                opacity: 1,
                y: 0,
              }
            : {
                opacity: 0,
                y: 20,
              }
        }
        transition={{
          duration: 0.7,
        }}
      >
        <Container>
          <Grid container="container" spacing={2}>
            <Grid
              item="item"
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{
                marginLeft: "6%",
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  fontFamily: "Kanit, sans-serif",
                  fontSize: "35px",
                  marginTop: "5%",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                Exploring the Multidisciplinary Marvels of Our University:
                Unveiling the Faculties Shaping Tomorrow's Leaders
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </motion.div>

      <motion.div
        ref={ref2}
        initial={{
          opacity: 0,
          y: 20,
        }}
        animate={
          inView2
            ? {
                opacity: 1,
                y: 0,
              }
            : {
                opacity: 0,
                y: 20,
              }
        }
        transition={{
          duration: 0.7,
        }}
      >
        <Container>
          <Grid container="container" spacing={2}>
            <Grid
              item="item"
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{
                marginLeft: "6%",
              }}
            >
              <Typography className="fac-des">
                "Discover KIU University, your gateway to a brighter future. Our
                university features eight fully operational faculties, each
                housing multiple departments. With a dedicated team of
                accomplished staff, we're here to empower you to reach your
                potential. At KIU, we believe in multidisciplinary learning,
                equipping you to tackle the challenges of a rapidly changing
                world. Join us and let's shape your promising future together."
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </motion.div>

      <motion.div
        ref={ref3}
        initial={{
          opacity: 0,
          y: 20,
        }}
        animate={
          inView3
            ? {
                opacity: 1,
                y: 0,
              }
            : {
                opacity: 0,
                y: 20,
              }
        }
        transition={{
          duration: 0.7,
        }}
      >
        <div className="fac-horizontal-divider"></div>
      </motion.div>

      <motion.div
        ref={ref4}
        initial={{
          opacity: 0,
          y: 20,
        }}
        animate={
          inView4
            ? {
                opacity: 1,
                y: 0,
              }
            : {
                opacity: 0,
                y: 20,
              }
        }
        transition={{
          duration: 0.7,
        }}
      >
        <Container>
          <Grid container="container" spacing={2}>
            <Grid
              item="item"
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{
                marginLeft: "6%",
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  fontFamily: "Kanit, sans-serif",
                  fontSize: "35px",
                  marginBottom: "5%",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                Faculties
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </motion.div>

      <Container>
        <Grid container="container" spacing={1}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              marginLeft: "6%",
            }}
          >
            <Card
              onClick={showDrawer1}
              style={{
                cursor: "pointer",
              }}
            >
              <div className="image-container">
                <CardMedia
                  component="img"
                  width="100%"
                  height="100%"
                  image={nursing}
                  alt="KIU UNI"
                  className="hover-effect-image"
                />
              </div>
              <CardContent>
                <h2 className="fac-hed">Faculty Of Nursing</h2>
              </CardContent>
            </Card>
          </Grid>

          <Drawer
            open={open1}
            closable={false}
            title={
              <MdOutlineCloseFullscreen
                onClick={() => {
                  setOpen1(false);
                }}
                className="close-fac-draw"
              >
                {" "}
              </MdOutlineCloseFullscreen>
            }
            onClose={() => {
              setOpen1(false);
            }}
            width={drawerWidth}
            style={{
              background: "rgba(0, 0, 0, 0.5)",
              WebkitBackdropFilter: "blur(10px)",
              backdropFilter: "blur(10px)",
              border: "1px solid rgba(0, 0, 0, 0.25)",
            }}
          >
            <FON />
          </Drawer>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              marginLeft: "6%",
            }}
          >
            <Card
              onClick={showDrawer2}
              style={{
                cursor: "pointer",
              }}
            >
              <div className="image-container">
                <CardMedia
                  component="img"
                  width="100%"
                  height="100%"
                  image={health}
                  alt="KIU UNI"
                  className="hover-effect-image"
                />
              </div>
              <CardContent>
                <h2 className="fac-hed">Faculty Of Health Sciences</h2>
              </CardContent>
            </Card>
          </Grid>

          <Drawer
            open={open2}
            closable={false}
            title={
              <MdOutlineCloseFullscreen
                onClick={() => {
                  setOpen2(false);
                }}
                className="close-fac-draw"
              >
                {" "}
              </MdOutlineCloseFullscreen>
            }
            onClose={() => {
              setOpen2(false);
            }}
            width={drawerWidth}
            style={{
              background: "rgba(0, 0, 0, 0.5)",
              WebkitBackdropFilter: "blur(10px)",
              backdropFilter: "blur(10px)",
              border: "1px solid rgba(0, 0, 0, 0.25)",
            }}
          >
            <FOHS />
          </Drawer>
        </Grid>
      </Container>

      <div className="fac-divider"></div>

      <Container>
        <Grid container="container" spacing={1}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              marginLeft: "6%",
            }}
          >
            <Card
              onClick={showDrawer3}
              style={{
                cursor: "pointer",
                minHeight: "100%",
              }}
            >
              <div className="image-container">
                <CardMedia
                  component="img"
                  width="100%"
                  height="100%"
                  image={management}
                  alt="KIU UNI"
                  className="hover-effect-image"
                />
              </div>
              <CardContent>
                <h2 className="fac-hed">Faculty Of Management</h2>
              </CardContent>
            </Card>
          </Grid>

          <Drawer
            open={open3}
            closable={false}
            title={
              <MdOutlineCloseFullscreen
                onClick={() => {
                  setOpen3(false);
                }}
                className="close-fac-draw"
              >
                {" "}
              </MdOutlineCloseFullscreen>
            }
            onClose={() => {
              setOpen3(false);
            }}
            width={drawerWidth}
            style={{
              background: "rgba(0, 0, 0, 0.5)",
              WebkitBackdropFilter: "blur(10px)",
              backdropFilter: "blur(10px)",
              border: "1px solid rgba(0, 0, 0, 0.25)",
            }}
          >
            <FOM />
          </Drawer>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              marginLeft: "6%",
            }}
          >
            <Card
              onClick={showDrawer4}
              style={{
                cursor: "pointer",
              }}
            >
              <div className="image-container">
                <CardMedia
                  component="img"
                  width="100%"
                  height="100%"
                  image={education}
                  alt="KIU UNI"
                  className="hover-effect-image"
                />
              </div>
              <CardContent>
                <h2 className="fac-hed">Faculty Of Education & Languages</h2>
              </CardContent>
            </Card>
          </Grid>

          <Drawer
            open={open4}
            closable={false}
            title={
              <MdOutlineCloseFullscreen
                onClick={() => {
                  setOpen4(false);
                }}
                className="close-fac-draw"
              >
                {" "}
              </MdOutlineCloseFullscreen>
            }
            onClose={() => {
              setOpen4(false);
            }}
            width={drawerWidth}
            style={{
              background: "rgba(0, 0, 0, 0.5)",
              WebkitBackdropFilter: "blur(10px)",
              backdropFilter: "blur(10px)",
              border: "1px solid rgba(0, 0, 0, 0.25)",
            }}
          >
            <FOEL />
          </Drawer>
        </Grid>
      </Container>

      <div className="fac-divider"></div>

      <Container>
        <Grid container="container" spacing={1}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              marginLeft: "6%",
            }}
          >
            <Card
              onClick={showDrawer5}
              style={{
                cursor: "pointer",
              }}
            >
              <div className="image-container">
                <CardMedia
                  component="img"
                  width="100%"
                  height="100%"
                  image={computer}
                  alt="KIU UNI"
                  className="hover-effect-image"
                />
              </div>
              <CardContent>
                <h2 className="fac-hed">
                  Faculty Of Computer Science & Engineering
                </h2>
              </CardContent>
            </Card>
          </Grid>

          <Drawer
            open={open5}
            closable={false}
            title={
              <MdOutlineCloseFullscreen
                onClick={() => {
                  setOpen5(false);
                }}
                className="close-fac-draw"
              >
                {" "}
              </MdOutlineCloseFullscreen>
            }
            onClose={() => {
              setOpen5(false);
            }}
            width={drawerWidth}
            style={{
              background: "rgba(0, 0, 0, 0.5)",
              WebkitBackdropFilter: "blur(10px)",
              backdropFilter: "blur(10px)",
              border: "1px solid rgba(0, 0, 0, 0.25)",
            }}
          >
            <FOCSE />
          </Drawer>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              marginLeft: "6%",
            }}
          >
            <Card
              onClick={showDrawer6}
              style={{
                cursor: "pointer",
                minHeight: "100%",
              }}
            >
              <div className="image-container">
                <CardMedia
                  component="img"
                  width="100%"
                  height="100%"
                  image={graduate}
                  alt="KIU UNI"
                  className="hover-effect-image"
                />
              </div>
              <CardContent>
                <h2 className="fac-hed">Faculty Of Graduate Studies</h2>
              </CardContent>
            </Card>
          </Grid>

          <Drawer
            open={open6}
            closable={false}
            title={
              <MdOutlineCloseFullscreen
                onClick={() => {
                  setOpen6(false);
                }}
                className="close-fac-draw"
              >
                {" "}
              </MdOutlineCloseFullscreen>
            }
            onClose={() => {
              setOpen6(false);
            }}
            width={drawerWidth}
            style={{
              background: "rgba(0, 0, 0, 0.5)",
              WebkitBackdropFilter: "blur(10px)",
              backdropFilter: "blur(10px)",
              border: "1px solid rgba(0, 0, 0, 0.25)",
            }}
          >
            <FOGS />
          </Drawer>
        </Grid>
      </Container>

      <div className="fac-divider"></div>

      <Container>
        <Grid container="container" spacing={1}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              marginLeft: "6%",
            }}
          >
            <Card
              onClick={showDrawer7}
              style={{
                cursor: "pointer",
              }}
            >
              <div className="image-container">
                <CardMedia
                  component="img"
                  width="100%"
                  height="100%"
                  image={behavio}
                  alt="KIU UNI"
                  className="hover-effect-image"
                />
              </div>
              <CardContent>
                <h2 className="fac-hed">Faculty Of Behavioural Sciences</h2>
              </CardContent>
            </Card>
          </Grid>

          <Drawer
            open={open7}
            closable={false}
            title={
              <MdOutlineCloseFullscreen
                onClick={() => {
                  setOpen7(false);
                }}
                className="close-fac-draw"
              >
                {" "}
              </MdOutlineCloseFullscreen>
            }
            onClose={() => {
              setOpen7(false);
            }}
            width={drawerWidth}
            style={{
              background: "rgba(0, 0, 0, 0.5)",
              WebkitBackdropFilter: "blur(10px)",
              backdropFilter: "blur(10px)",
              border: "1px solid rgba(0, 0, 0, 0.25)",
            }}
          >
            <FOBS />
          </Drawer>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              marginLeft: "6%",
            }}
          >
            <Card
              onClick={showDrawer8}
              style={{
                cursor: "pointer",
                minHeight: "100%",
              }}
            >
              <div className="image-container">
                <CardMedia
                  component="img"
                  width="100%"
                  height="100%"
                  image={livestock}
                  alt="KIU UNI"
                  className="hover-effect-image"
                />
              </div>
              <CardContent>
                <h2 className="fac-hed">
                  KIU School Of Agriculture And Livestock Management
                </h2>
              </CardContent>
            </Card>
          </Grid>

          <Drawer
            open={open8}
            closable={false}
            title={
              <MdOutlineCloseFullscreen
                onClick={() => {
                  setOpen8(false);
                }}
                className="close-fac-draw"
              >
                {" "}
              </MdOutlineCloseFullscreen>
            }
            onClose={() => {
              setOpen8(false);
            }}
            width={drawerWidth}
            style={{
              background: "rgba(0, 0, 0, 0.5)",
              WebkitBackdropFilter: "blur(10px)",
              backdropFilter: "blur(10px)",
              border: "1px solid rgba(0, 0, 0, 0.25)",
            }}
          >
            <KSLM />
          </Drawer>
        </Grid>
      </Container>

      <div className="fac-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              marginLeft: "6%",
            }}
          >
            <Card
              onClick={showDrawer9}
              style={{
                cursor: "pointer",
              }}
            >
              <div className="image-container">
                <CardMedia
                  component="img"
                  width="100%"
                  height="100%"
                  image={law}
                  alt="KIU UNI"
                  className="hover-effect-image"
                />
              </div>
              <CardContent>
                <h2 className="fac-hed">Faculty of Law</h2>
              </CardContent>
            </Card>
          </Grid>

          <Drawer
            open={open9}
            closable={false}
            title={
              <MdOutlineCloseFullscreen
                onClick={() => {
                  setOpen9(false);
                }}
                className="close-fac-draw"
              >
                {" "}
              </MdOutlineCloseFullscreen>
            }
            onClose={() => {
              setOpen9(false);
            }}
            width={drawerWidth}
            style={{
              background: "rgba(0, 0, 0, 0.5)",
              WebkitBackdropFilter: "blur(10px)",
              backdropFilter: "blur(10px)",
              border: "1px solid rgba(0, 0, 0, 0.25)",
            }}
          >
            <FOL />
          </Drawer>
        </Grid>
      </Container>

      <motion.div
        ref={ref5}
        initial={{
          opacity: 0,
          y: 20,
        }}
        animate={
          inView5
            ? {
                opacity: 1,
                y: 0,
              }
            : {
                opacity: 0,
                y: 20,
              }
        }
        transition={{
          duration: 0.7,
        }}
      >
        <div className="fac-horizontal-divider"></div>
      </motion.div>

      <motion.div
        ref={ref6}
        initial={{
          opacity: 0,
          y: 20,
        }}
        animate={
          inView6
            ? {
                opacity: 1,
                y: 0,
              }
            : {
                opacity: 0,
                y: 20,
              }
        }
        transition={{
          duration: 0.7,
        }}
      >
        <Container>
          <Grid container="container" spacing={2}>
            <Grid
              item="item"
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{
                marginLeft: "6%",
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  fontFamily: "Kanit, sans-serif",
                  fontSize: "35px",
                  marginBottom: "5%",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                Faculty Deans
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </motion.div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}
            sx={{
              ml: "6%",
            }}
          >
            <Box
              sx={{
                width: "200px",
                height: "250px",
              }}
            >
              <LazyLoadImage
                src="https://kiu-website.s3.ap-southeast-1.amazonaws.com/Resource/1721387193498_ssp.jpg"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                alt="KIU UNI"
              />
            </Box>
            <h3 className="fac-de-pos">Prof. S.S.P. Warnakulasuriya</h3>
            <p className="fac-de-pos1">Honorary Dean of Faculty of Nursing</p>
            <p className="fac-de-pos2">
              PhD (USJP), MNSc (Adelaide-Australia), PGDCP (Colombo), BScN
              (Hons.) (OUSL), RN, RMN, Dip in Teaching and Supervision
            </p>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            sx={{
              ml: "6%",
            }}
          >
            <h3 className="fac-de-pub">Career Summery</h3>
            <p className="fac-de-career">
              He entered the nursing profession in 1987. He holds a BSc with
              second class upper division Honours in Nursing from the Open
              University of Sri Lanka, a Master of Science in Nursing from the
              University of Adelaide in Australia, and a PhD from the University
              of Sri Jayewardenepura. Prior to joining the University of
              Colombo, he has rendered his service to the National Institute of
              Health and Social Sciences (NIHSS) in the Republic of Seychelles
              from 2003 to 2005. He has worked in the Faculty of Medical
              Sciences in the university of Sri Jayewardenepura for 12 years and
              Ministry of health for 17 years.
            </p>
            {/* <h3 className="fac-de-pub">Contact: +947699928636 </h3> */}
            <p className="fac-de-career">Email: dean_fon@kiu.ac.lk</p>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={8}
            lg={8}
            xl={8}
            sx={{
              ml: "6%",
            }}
          >
            <h3 className="fac-de-pub">Publications</h3>
            <ul className="fac-de-pub-list">
              <li>
                Warnakulasuriya SSP, Peiris-John RJ, Sathiakumar N,
                Wickremasinghe AR Musculoskeletal Symptoms and Associated
                factors among mail sorters in Sri Lanka. 10th Southeast Asia
                Regional Science Meeting of the International Epidemiological
                Association: May 2010, Colombo, Sri Lanka.
              </li>
              <Button
                style={buttonStyles1}
                onClick={() => handleBtnClick("/Research#researchSection")}
              >
                View More
              </Button>
            </ul>
          </Grid>
        </Grid>
      </Container>

      <div className="fac-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}
            sx={{
              ml: "6%",
            }}
          >
            <Box
              sx={{
                width: "200px",
                height: "250px",
              }}
            >
              <LazyLoadImage
                src="https://kiu-website.s3.ap-southeast-1.amazonaws.com/Resource/1721387262663_profAnoma.jpg"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                alt="KIU UNI"
              />
            </Box>
            <h3 className="fac-de-pos">Dr. Anoma Edirimanna</h3>
            <p className="fac-de-pos1">Dean of the Faculty of Management</p>
            <p className="fac-de-pos2">
              PhD in Business and Management (MSU, Malaysia)
              <br />
              MBA in Management of Technology (UoM, Sri Lanka)
              <br />
              BSc in Physical Science (USJP, Sri Lanka)
              <br />
              Passed Finalist (IBSL)
            </p>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            sx={{
              ml: "6%",
            }}
          >
            <h3 className="fac-de-pub">Career Summery</h3>
            <p className="fac-de-career">
              Dr. Anoma Edirimanna is a veteran academic with over 25 years of
              experience in tertiary education and academic administration,
              currently serving as the Dean of the Faculty of Management. Her
              extensive academic qualifications include a BSc in Physical
              Sciences from the University of Sri Jayewardenepura, an MBA in
              Management of Technology from the University of Moratuwa, a PhD in
              Business and Management from the Management and Science University
              in Malaysia, and she is a passed finalist from the Institute of
              Bankers of Sri Lanka. Dr. Anoma began her career as a Computer
              Instructor at the University of Sri Jayewardenepura in 1997 and
              subsequently joined the Australian College of Business and
              Technology (ACBT) as a Lecturer in 1998. Over two decades at ACBT,
              she made significant contributions as a senior lecturer, research
              supervisor, examiner, student advisor, sports in-charge, and
              mentor for junior lecturers. She played a pivotal role in
              establishing ACBT's UK arm in partnership with Pearson and
              Middlesex University, serving as the Head of BTEC HND programs for
              five years. In 2022, Dr. Anoma transitioned to roles at several
              prestigious institutions, including the Chartered Institute of
              Personnel Management, the University of Sri Jayewardenepura, BMS
              in partnership with Northumbria University, UTS Insearch in
              partnership with the University of Technology, Sydney, and ECU/ECC
              in partnership with Edith Cowan University, Australia. Her
              teaching expertise spans Business Mathematics, Statistics,
              Business Analytics, Quantitative Research Methodology, and HR
              Analytics. Her research on Enterprise Risk Management in higher
              education has led to numerous publications and presentations at
              international conferences. Dr. Edirimanna is known for her
              communication and interpersonal skills, advanced research
              capabilities, and proficiency in data analysis tools such as MS
              Excel, SPSS, Smart PLS, and AMOS. Her dedication to education,
              professional development, and academic excellence is reflected in
              her extensive teaching, research, and administrative
              contributions.
            </p>
            {/* <h3 className="fac-de-pub">Contact : +94 72 990 7630 </h3> */}
            <p className="fac-de-career">Email: dean_fom@kiu.ac.lk</p>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={8}
            lg={8}
            xl={8}
            sx={{
              ml: "6%",
            }}
          >
            <h3 className="fac-de-pub">Publications</h3>
            <ul className="fac-de-pub-list">
              <li>
                Perera, A. A. S., Rahmat, A.K., Khatibi, A. & Azam, S.M.F.
                (2022). Reliability Assessment of Indicators Measuring the
                Impact of Enterprise Risk Management on Performance of Higher
                Education Institutions in Sri Lanka. Asian Journal of University
                Education (AJUE). Volume 18, Number 1, January 2022. pp (300 –
                321)
              </li>
              <Button
                style={buttonStyles1}
                onClick={() => handleBtnClick("/Research#researchSection")}
              >
                View More
              </Button>
            </ul>
          </Grid>
        </Grid>
      </Container>

      <div className="fac-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}
            sx={{
              ml: "6%",
            }}
          >
            <Box
              sx={{
                width: "200px",
                height: "250px",
              }}
            >
              <LazyLoadImage
                src="https://kiu-website.s3.ap-southeast-1.amazonaws.com/Resource/1721387313287_kanthi.png"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                alt="KIU UNI"
              />
            </Box>
            <h3 className="fac-de-pos">Dr. Kanthi Hettigoda</h3>
            <p className="fac-de-pos1">
              Dean of Faculty of Behavioural Sciences
            </p>
            <p className="fac-de-pos2">
              PhD (Psychology) - UK MPhil Clinical Psychology FGS UOC MSc.
              Organizational Management- PGIA UOP B. A (Hons.) Psychology
              Faculty of Arts UOP
            </p>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            sx={{
              ml: "6%",
            }}
          >
            <h3 className="fac-de-pub">Career Summery</h3>
            <p className="fac-de-career">
              Dr. Kanthi Hettigoda is a distinguished Chartered Clinical
              Psychologist and currently serves as the Honorary Dean of the
              Faculty of Behavioral Sciences. With a rich professional
              background, she commenced her career as a lecturer at the Sri
              Lanka Foundation Institute (SLFI) in 2003, where she contributed
              significantly to the field of psychology until 2013. Following her
              tenure at SLFI, Dr. Kanthi Hettigoda took on a challenging role as
              Lieutenant Commander in the Sri Lanka Navy, showcasing her
              leadership skills and dedication from 2013 to 2022. In 2022, Dr.
              Kanthi Hettigoda transitioned to academia once again, assuming the
              role of Senior Lecturer at the prestigious University of
              Peradeniya. Her commitment to advancing the field of Behavioral
              Sciences is evident in her continued contributions as an educator
              and leader. As the Honorary Dean, she brings a wealth of
              experience, expertise, and a passion for nurturing the next
              generation of professionals in the realm of psychology and
              behavioral sciences. Dr. Kanthi Hettigoda's career journey
              reflects a remarkable blend of academic excellence, military
              service, and a steadfast commitment to the advancement of
              psychological understanding and well-being.
            </p>
            <h3 className="fac-de-pub">Contact</h3>
            <p className="fac-de-career">Email: dean_fbs@kiu.ac.lk</p>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={8}
            lg={8}
            xl={8}
            sx={{
              ml: "6%",
            }}
          >
            <h3 className="fac-de-pub">Publications</h3>
            <ul className="fac-de-pub-list">
              <li>
                Jayasekera, M. M. P. T., De Silva, N. L., Edirisinghe, E. M. D.
                T., Samarawickrama, T., Sirimanna, S. W. D. R. C., Hettigoda, K,
                … & Wijesinghe, R. A. N. K. (2023). A prospective cohort study
                on post COVID syndrome from a tertiary care centre in Sri Lanka.
                Scientific Reports, 13(1), 1–9
              </li>
              {/* <li></li>
                            <li></li>
                            <li></li>
                            <li></li> */}
              <Button
                style={buttonStyles1}
                onClick={() => handleBtnClick("/Research#researchSection")}
              >
                View More
              </Button>
            </ul>
          </Grid>
        </Grid>
      </Container>

      <div className="fac-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}
            sx={{
              ml: "6%",
            }}
          >
            <Box
              sx={{
                width: "200px",
                height: "250px",
              }}
            >
              <LazyLoadImage
                src="https://kiu-website.s3.ap-southeast-1.amazonaws.com/Resource/1721387609681_profWas.jpeg"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                alt="KIU UNI"
              />
            </Box>
            <h3 className="fac-de-pos">Prof. Wasantha Seneviratne</h3>
            <p className="fac-de-pos1">Honorary Dean of Faculty of Law</p>
            <p className="fac-de-pos2">
              M.Phil in Law, Attorney-at-Law,University of Colombo
            </p>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            sx={{
              ml: "6%",
            }}
          >
            <h3 className="fac-de-pub">Career Summery</h3>
            <p className="fac-de-career">
              Professor Wasantha Seneviratne currently holds the position of
              Chair Professor of Public and International Law at the Faculty of
              Law, University of Colombo, Sri Lanka. Additionally, she serves as
              the Director of the Centre for the Study of Human Rights, a
              research institute affiliated with the University of Colombo. With
              nearly 28 years of experience in teaching, researching, and
              publishing in International Law, Human Rights Law, and
              Humanitarian Law, she imparts her expertise in Public
              International Law, International Humanitarian Law, and Human
              Rights Law at both undergraduate and postgraduate levels in
              various law teaching institutions in Sri Lanka. Prof. Seneviratne
              has authored, edited, and published numerous book chapters and
              research articles in local and international journals. Her
              research areas include Theories of Public International Law,
              humanitarian interventions, the concept of responsibility to
              protect, and the continued relevance of humanitarian law in
              post-conflict situations, among others. Currently, she serves as
              the Editor-in-Chief of the 'Sri Lanka Journal of International
              Law,' a refereed journal published by the University of Colombo.
              Beyond academia, she actively disseminates knowledge in the
              community, particularly to members of the armed forces and the
              police. Her contributions extend to roles such as a Member of the
              Expert Committee on making a New Constitution, Team Leader, Member
              of the National Monitoring Committee of Child Rights, and a Member
              of the Law Reforms Committee of the National Child Protection
              Authority. She also served as a Member of the Former National
              Steering Committee on Police Reforms. Notably, she is the State
              Volume Editor for the Encyclopedia of Public International Law in
              Asia Sri Lanka Volume, recently launched by Brill International
              Publishers.
            </p>
            <h3 className="fac-de-pub">Contact</h3>
            <p className="fac-de-career">Email: Dean_fol@kiu.ac.lk</p>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={8}
            lg={8}
            xl={8}
            sx={{
              ml: "6%",
            }}
          >
            <h3 className="fac-de-pub">Publications</h3>
            <ul className="fac-de-pub-list">
              <li>
                Wasantha Seneviratne & et al, Bilateral Labour Agreements
                Between Sri Lanka and Other Jurisdictions: A Critical Legal
                Analysis, ISSN: 2706-0268, Centre for Migration Research and
                Development, 2023
              </li>
              <Button
                style={buttonStyles1}
                onClick={() => handleBtnClick("/Research#researchSection")}
              >
                View More
              </Button>
            </ul>
          </Grid>
        </Grid>
      </Container>

      <div className="fac-divider"></div>

      <Container>
        <Grid container="container" spacing={1}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}
            sx={{
              ml: "6%",
            }}
          >
            <Box
              sx={{
                width: "200px",
                height: "250px",
              }}
            >
              <LazyLoadImage
                src="https://kiu-website.s3.ap-southeast-1.amazonaws.com/Resource/1721387744867_Akila.jpg"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                alt="KIU UNI"
              />
            </Box>
            <h3 className="fac-de-pos">Akila Randika Jayamaha</h3>
            <p className="fac-de-pos1">
              Dean of the Faculty of Graduate Studies
            </p>
            <p className="fac-de-pos2">
              BSc (Hons) in Nursing (Ruhuna), MSc in Applied Epidemiology
              (Peradeniya) MSc in Nursing (KIU), PhD (4th Year, Reading)
              (University of Leicester, UK)
            </p>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            sx={{
              ml: "6%",
            }}
          >
            <h3 className="fac-de-pub">Career Summery</h3>
            <p className="fac-de-career">
              His academic journey commenced in 2017 when he joined KIU as a
              probationary lecturer at the Faculty of Nursing. Over the years,
              he has assumed various roles within the Faculty of Nursing,
              including serving as the Assistant Head of the Department of
              Nursing, Acting Head of the Department of Nursing, and Acting Dean
              of the Faculty of Nursing. From 2019 to 2022, he had the privilege
              of leading the Research Unit at KIU. Currently, he holds the
              position of Chief Growth Officer in the Research and Development
              Division. In 2022, he was honored with the appointment as the Dean
              of the Faculty of Graduate Studies.
            </p>
            <h3 className="fac-de-pub">Contact: 0777614918 </h3>
            <p className="fac-de-career">Email: akila@kiu.ac.lk</p>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={8}
            lg={8}
            xl={8}
            sx={{
              ml: "6%",
            }}
          >
            <h3 className="fac-de-pub">Publications</h3>
            <ul className="fac-de-pub-list">
              <li>
                Kalingamudali, Y. T., Jalini, P., Jayamaha, A. R., & Jayaratne,
                D. L. (2023). Knowledge, attitudes, and practices on the usage
                of antiseptics prior to invasive medical procedures: Evidence
                from Sri Lankan Healthcare Professionals. SAGE Open Medicine,
                11,1-9.
              </li>
              <Button
                style={buttonStyles1}
                onClick={() => handleBtnClick("/Research#researchSection")}
              >
                View More
              </Button>
            </ul>
          </Grid>
        </Grid>
      </Container>

      <div className="fac-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}
            sx={{
              ml: "6%",
            }}
          >
            <Box
              sx={{
                width: "200px",
                height: "250px",
              }}
            >
              <LazyLoadImage
                src="https://kiu-website.s3.ap-southeast-1.amazonaws.com/Resource/1721387907324_sunimal.jpg"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                alt="KIU UNI"
              />
            </Box>
            <h3 className="fac-de-pos">
              Dr. R. M. S. Rathnayake (Honorary Dean)
            </h3>
            <p className="fac-de-pos1">
              Honorary Dean of Faculty of Computer Science & Engineering
            </p>
            <p className="fac-de-pos2">
              PhD (National University of Singapore) BSc Engineering Hons
              (Moratuwa)
            </p>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            sx={{
              ml: "6%",
            }}
          >
            <h3 className="fac-de-pub">Career Summery</h3>
            <p className="fac-de-career">
              Dr. R. M. S. Rathnayake is an educator and a researcher interested
              in computer systems teaching and research, especially focusing on
              cloud computing, networking, high-performance computing, security,
              and trusted computing. He has more than a decade of experience in
              teaching and research at the university level and a wide range of
              experience as a consultant for multiple government and corporate
              institutions.
              <br />
              In addition to his full-time role as a Senior Lecturer attached to
              the Department of Computer Science Engineering at University of
              Moratuwa, he also serves as the Director for IT Services,
              University of Moratuwa and as a Director/Board Member of LK Domain
              Registry. <br />
              Dr. Rathnayake has published in reputed international conferences
              including SC, ICPP, and holds the Associate Membership of
              Institution of Engineers Sri Lanka (IESL), and the full
              memberships of Institution of Electrical and Electronics Engineers
              (IEEE) and the Association of Computer Machinery (ACM).
            </p>
            <p className="fac-de-career">Email: dean_foc@kiu.ac.lk</p>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={8}
            lg={8}
            xl={8}
            sx={{
              ml: "6%",
            }}
          >
            <h3 className="fac-de-pub">Publications</h3>
            <ul className="fac-de-pub-list">
              <li>
                Characterizing the Cost-Accuracy Performance of Cloud
                Applications. In Proceedings of the 49th International
                Conference on Parallel Processing (ICPP): Workshops, Ed- monton,
                Canada, 2020, ACM.
              </li>
              <Button
                style={buttonStyles1}
                onClick={() => handleBtnClick("/Research#researchSection")}
              >
                View More
              </Button>
            </ul>
          </Grid>
        </Grid>
      </Container>

      <div className="fac-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}
            sx={{
              ml: "6%",
            }}
          >
            <Box
              sx={{
                width: "200px",
                height: "250px",
              }}
            >
              <LazyLoadImage
                src="https://kiu-website.s3.ap-southeast-1.amazonaws.com/Resource/1721389873012_Picture2.jpg"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                alt="KIU UNI"
              />
            </Box>
            <h3 className="fac-de-pos">Mrs.Nadeeka Ranadeva</h3>
            <p className="fac-de-pos1">Dean of Faculty of Health Sciences</p>
            <p className="fac-de-pos2">
              BSc Sp. Human Biology (FMS, USJP), Master in Med Stat (Reading,
              UOK), MSc Molecular Pathology (FOM, UOC), PhD (Reading)
            </p>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            sx={{
              ml: "6%",
            }}
          >
            <h3 className="fac-de-pub">Career Summery</h3>
            <p className="fac-de-career">
              Beginning her career as a Probationary Lecturer in 2015, she has
              advanced to the role of Senior Lecturer in the Faculty of Health
              Sciences and currently holds the esteemed position of Dean of the
              Faculty of Health Sciences. She has specialized in Human Biology
              (Faculty of Medicine, University of Sri Jayewardenepura) and
              Molecular Pathology (Faculty of Medicine, University of Colombo).
              In 2023, she received a prestigious scholarship for special
              training in Precision Medicine from the University of Hong Kong.
              In 2019 has won the Bill and Melinda gates travel award. She has
              also served as the Head of the Department of Biomedical Science
              and Director of the Center for Quality Assurance at KIU. Her
              research interests are Precision medicine, Cancer genetics,
              addiction genetics and proteomics.
            </p>
            {/* <h3 className="fac-de-pub">Contact: +94718355314</h3> */}
            <p className="fac-de-career">Email: dean_foh@kiu.ac.lk</p>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={8}
            lg={8}
            xl={8}
            sx={{
              ml: "6%",
            }}
          >
            <h3 className="fac-de-pub">Publications</h3>
            <ul className="fac-de-pub-list">
              <li>
                1. DR Perera, ND Ranadeva, K Sirisena, KJ Wijesinghe; Roles of
                NS1 Protein in flavivirus pathogenesis, ACS Infectious Diseases
                10 (1), 20-56
              </li>
              <Button
                style={buttonStyles1}
                onClick={() => handleBtnClick("/Research#researchSection")}
              >
                View More
              </Button>
            </ul>
          </Grid>
        </Grid>
      </Container>

      <div className="fac-divider"></div>

      {/* <div className="fac-divider"></div> */}

      {/* <Container>
                <Grid container="container" spacing={2}>

                    <Grid item="item" xs={12} sm={12} md={4} lg={4} xl={4}>
                        <h3 className='fac-de-pub'>Publications</h3>
                        <ul className='fac-de-pub-list'>
                            <li>Publication 1</li>
                            <Button style={buttonStyles1} onClick={() => handleBtnClick('/Research')}>View More</Button>
                        </ul>
                    </Grid>

                    <Grid item="item" xs={12} sm={12} md={5} lg={5} xl={5}>
                        <h3 className='fac-de-pub'>Career Summery</h3>
                        <p className='fac-de-career'>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                            incididunt ut labore et dolore magna aliqua. Amet cursus sit amet dictum sit
                            amet. Amet mattis vulputate enim nulla aliquet porttitor lacus luctus. Mi quis
                            hendrerit dolor magna eget est lorem ipsum. Arcu cursus vitae congue mauris
                            rhoncus. Ornare lectus sit amet est placerat in
                        </p>
                        <h3 className='fac-de-pub'>Contact</h3>
                        <p className='fac-de-career'>
                            Email:
                            <br/>
                            Phone:
                        </p>
                    </Grid>

                    <Grid item="item" xs={12} sm={12} md={3} lg={3} xl={3}>

                        <img alt="KIU Uni" 
                            src='https://news.harvard.edu/wp-content/uploads/2015/10/101615_humanitarian_2811_605.jpg'
                            width='100%'
                            className='fac-img'/>
                        <h3 className='fac-de-pos'>Name</h3>
                        <h3 className='fac-de-pos'>Position</h3>
                        <h3 className='fac-de-pos'>Education</h3>

                    </Grid>
                </Grid>
            </Container> */}

      <div className="footer-div">
        <Footer />
      </div>
    </div>
  );
};

export default Faculties;
