import { Button, Container, Grid } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import "./Faculty.css";

const FOL = () => {
  const buttonStyles1 = {
    backgroundColor: "transparent",
    border: "2px solid #f21111", // Blue outline
    color: "#f21111", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const navigate = useNavigate();
  const handleBtnClick = (path) => {
    navigate(path);
  };
  return (
    <div>
      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 className="fac-title">Faculty of Law</h3>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <p className="fac-descrip">
              The Faculty of Law of KIU is the gateway to becoming a world-class
              professional in the field of law with a Sri Lankan touch. Our law
              graduates who will pursue their degree at KIU will not only emerge
              with a highly reputed four-year honours degree in law but will be
              equipped with a profound understanding of the enduring bedrock
              principles that underpin justice. As they venture out into the
              world, they carry with them a treasure trove of advantages – a
              razor-sharp intellect, a moral compass guiding them, and the
              adeptness to navigate intricate international as well as local
              legal landscapes with finesse. <br />
              A true jewel in the crown of our Faculty of Law is the renowned
              moot court, where students can sharpen their advocacy skills,
              grapple with authentic legal scenarios, and cultivate the
              confidence to stand before the bench. It is here that the art of
              argumentation reaches its zenith, preparing our students for the
              formidable challenges of presenting robust and pragmatic legal
              arguments in a courtroom or inculcating the intricacies of
              negotiation, mediation and arbitration through alternative methods
              of dispute settlement mechanisms. <br />
              Our commitment to providing our students with a global perspective
              further elevates the stature of our Law Faculty. Graduates have
              the unique opportunity to partake in international collaborations
              and exchanges and engage in groundbreaking legal research,
              cultivating a global outlook that is increasingly indispensable in
              today’s interconnected world. Our graduates are not just legal
              professionals; they are ambassadors of justice on the global
              stage, ready to contribute to the advancement of the rule of law
              and the betterment of society. Equipped with the extraordinary
              opportunities offered by our faculty, they are prepared not only
              to navigate the challenging legal landscape but to redefine it.
              These graduates emerge as world-class legal professionals, poised
              to uphold justice in a rapidly evolving world. Our Faculty of Law
              is the platform where the journey begins, but its impact resonates
              on a global scale, leaving an indelible mark on the world of law
              and justice. <br />
            </p>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={6} lg={6} xl={6}>
            <h3 className="fac-dean-hed">Dean</h3>
            <p className="fac-descrip1">
              Professor Wasantha Seneviratne <br />
              M.Phil in Law, Attorney-at-Law
              <br />
              University of Colombo
              <br />
              Contact: +94 77 123 4567
              <br />
              Email: Dean_fol@kiu.ac.lk
            </p>
          </Grid>

          <Grid item="item" xs={12} sm={12} md={6} lg={6} xl={6}>
            {/* <h3 className='fac-hod-hed'>Head of Department</h3>
                        <p className='fac-descrip1'>
                            Prof. Koliya Pulasinghe<br/>BSc Eng. (Hons) in Computer Systems & Engineering,
                                University of Moratuwa, Sri Lanka, 1996<br/>
                            Contact: +94 77 123 4567<br/>
                            Email: Dean_fol@kiu.ac.lk
                        </p> */}
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 className="fac-depts-hed">Departments</h3>
            <ul className="fac-depts-list">
              <li className="fac-depts-list-item">Department of Law</li>
            </ul>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 className="fac-fas-hed">Facilities</h3>
            <p className="fac-descrip1">
              This programme complies with local and global standards, which
              would certainly offer an unparallel academic qualification to
              successfully entre into the legal profession. This degree
              programme provides students with the necessary knowledge, skills,
              attitudes, and training in the field of Law with confidence in the
              moot court practices, and real courtrooms and many more. We are
              facilitating an industrial placement in leading chambers of law as
              a compulsory requirement of the degree programme, which will
              expose you to valuable industry and research experience. Students
              who wish to pursue a career in the field of academia and research
              organizations greatly benefit from the dissertation that require
              to engage in legal research in an area chosen to advance the
              knowledge gained through the LLB degree programme conducted in the
              English medium which covers 120 credits of academic exposure. This
              further improves their legal writing skills, research skills and
              analytical skills and advances their confidence to work in any
              place in anywhere in the world competently.
              <br />
              Especially, step into the refined world of legal prowess with the
              Moot Court in our LLB program. Immerse yourself in the art of
              persuasive advocacy as you navigate complex cases, honing your
              skills in a simulated courtroom environment. Our practice sessions
              are meticulously crafted to instill confidence, eloquence, and a
              deep understanding of legal strategy.
              <br />
              Unleash your potential as a legal luminary, mastering the nuances
              of moot court procedures. Our program offers a dynamic platform
              where theory meets practice, allowing you to seamlessly transition
              from the lecture-room to the court-room.
              <br />
              Join us in the pursuit of excellence as we empower future legal
              minds to articulate compelling arguments, analyze intricate legal
              principles, and present with grace under pressure. Elevate your
              LLB experience with the transformative power of moot court
              practice, where the journey to legal brilliance begins.
            </p>
          </Grid>
        </Grid>
      </Container>

      {/* <Container>
                <Grid container="container" spacing={2}>
                    <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Button style={buttonStyles1} onClick={() => handleBtnClick('/WeOffer')}>View Courses</Button>
                    </Grid>
                </Grid>
            </Container> */}
    </div>
  );
};

export default FOL;
